import classNames from 'classnames';
import Slider, { SLIDER_TYPES } from '../Slider';
import { REWARD_AMOUNT_TYPES } from '../../config/rewards';

import IconCircled, { ICON_CIRCLE_SIZE } from '../IconCircled';
import './CircularRewardsCarousel.scss';

export default function CircularRewardsCarousel({ rewards, onClick, getTrackerProps }) {
  return (
    <div className="CircularRewardsCarousel">
      <Slider
        type={SLIDER_TYPES.OVERFLOW}
        autoplay={false}
        rewind={false}
        paginationDots={false}
        fixedPerPage
        gap={12}
        slidesPerPage={3}
        trackPadding={false}
        slides={rewards.map((reward) => {
          const {
            uid,
            rewardAmount,
            amountType,
            type,
            store: { cardBgColor, logoUrl },
          } = reward;

          return {
            key: `reward-preview-${uid}`,
            slide: (
              <button
                type="button"
                className={classNames('CircularRewardsCarousel__wrapper', {
                  disabled: !onClick,
                })}
                onClick={() => onClick?.(reward)}
                {...getTrackerProps?.(reward)}
              >
                <div className="CircularRewardsCarousel__icon">
                  <IconCircled
                    key={`slide-reward-${uid}`}
                    icon={<img src={logoUrl} alt="logo" />}
                    backgroundColor={cardBgColor}
                    size={ICON_CIRCLE_SIZE.LARGE}
                    isWhiteLogo
                  />
                  <div className="CircularRewardsCarousel__icon-amount">
                    {REWARD_AMOUNT_TYPES[amountType]?.getText({
                      rewardAmount,
                      type,
                    })}
                  </div>
                </div>
              </button>
            ),
          };
        })}
      />
    </div>
  );
}
