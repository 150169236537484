import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardSection from '../../../global/DashboardSection';
import { getGenericError } from '../../../utils/errors';
import { getRewards, getConfigurations } from '../../../utils/service';
import { REWARD_PREVIEW_TYPES, REWARD_TYPE } from '../../../config/rewards';
import {
  ANCHOR_DEALS,
  CATEGORY_SEARCH_PARAM,
  REWARD_ID_ROUTE_PARAM,
  ROUTE_DEALS,
  ROUTE_DEAL_STORE_REWARD_ID,
  STORE_ID_ROUTE_PARAM,
} from '../../../config/routes';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import RedeemModal from '../../Reward/RedeemModal';
import { FLAGS, getDealsFilters } from '../../Rewards/utils';
import RewardItem from '../../Rewards/RewardItem';
import Slider, { ARROWS_VERTICAL_POSITION, SLIDER_TYPES } from '../../../global/Slider';
import { SIZE_SM, SIZE_XS, isMobile } from '../../../../../hooks/useBreakPoint';

import './DealsPreview.scss';

const PREVIEW_PAGE_SIZE_DEFAULT = 10;
const PREVIEW_PAGE_SIZE_FEATURED = 5;

const SLIDES_PER_PAGE_DEFAULT = 4;
const SLIDES_PER_PAGE_UNLOCK = 2;
const SLIDES_PER_PAGE_FEATURED = 3;
const DEALS_LIMIT = 4;

const {
  dashboard: {
    instantDeals: {
      viewAllButton: trackerViewAllButton,
    },
  },
} = TRACKER_IDS;

const DealsPreview = ({
  type = REWARD_PREVIEW_TYPES.DEFAULT,
  title,
  subTitle,
  rewardId,
  category,
  withCta = true,
  alwaysShowCta = false,
  showLoading = true,
  showOnEmptyResults = false,
  trackerProps = {},
}) => {
  const history = useHistory();
  const mobileSM = isMobile([SIZE_SM, SIZE_XS]) && SIZE_SM;

  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [rewardProperties, setRewardProperties] = useState({});
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [redeemRewardItem, setRedeemRewardItem] = useState(null);
  const [openRedeemModal, setOpenRedeemModal] = useState(false);
  const [isEmptyState, setIsEmptyState] = useState(false);
  const {
    isUnlockType,
    isFeaturedType,
    isHotType,
    isSimilarType,
    isCategoryType,
    pageSize,
    slidesPerPage,
  } = useMemo(() => {
    let previewPageSize = PREVIEW_PAGE_SIZE_DEFAULT;
    let slidesPerPageSize = SLIDES_PER_PAGE_DEFAULT;

    switch (type) {
      case REWARD_PREVIEW_TYPES.STARTER:
        if (rewards.length === 1 && mobileSM) {
          slidesPerPageSize = 1;
        }
        break;
      case REWARD_PREVIEW_TYPES.FEATURED:
        previewPageSize = PREVIEW_PAGE_SIZE_FEATURED;
        slidesPerPageSize = SLIDES_PER_PAGE_FEATURED;
        break;
      case REWARD_PREVIEW_TYPES.UNLOCK:
        slidesPerPageSize = SLIDES_PER_PAGE_UNLOCK;
        break;
      default:
        break;
    }

    return {
      isUnlockType: type === REWARD_PREVIEW_TYPES.UNLOCK,
      isFeaturedType: type === REWARD_PREVIEW_TYPES.FEATURED,
      isHotType: type === REWARD_PREVIEW_TYPES.HOT,
      isCategoryType: type === REWARD_PREVIEW_TYPES.CATEGORY,
      isSimilarType: type === REWARD_PREVIEW_TYPES.SIMILAR,
      pageSize: previewPageSize,
      slidesPerPage: slidesPerPageSize,
    };
  }, [type, rewards.length, mobileSM]);

  const loadRewards = useCallback(async () => {
    setIsLoading(true);
    const {
      data,
      error: rewardsError,
    } = await getRewards({
      pageSize,
      page: 1,
      type,
      ...getDealsFilters({ type, rewardId, categoryId: category?.uid }),
    });

    if (rewardsError) {
      setIsEmptyState(true);
      setRewards([]);
      setError(rewardsError.message || getGenericError());
      setIsLoading(false);
      return;
    }
    setRewards(data.rewards);
    setIsEmptyState(!data.rewards.length);
    setError('');
    setIsLoading(false);
  }, [type, rewardId, pageSize, category?.uid]);

  const rewardsConfig = useCallback(async () => {
    setIsLoadingConfig(true);
    const { value, error: configError } = await getConfigurations('rewardProperties');
    if (!configError) {
      setRewardProperties(value);
    }
    setIsLoadingConfig(false);
  }, []);

  const handleOnClick = useCallback(() => {
    let categoryParam = isHotType
      ? FLAGS.IS_HOT
      : ((isCategoryType || isSimilarType) && category?.routeName) || FLAGS.ALL;

    if (type === REWARD_PREVIEW_TYPES.EXCLUSIVE) {
      categoryParam = FLAGS.IS_EXCLUSIVE;
    }

    history.push({
      pathname: ROUTE_DEALS,
      search: new URLSearchParams({ [CATEGORY_SEARCH_PARAM]: categoryParam }).toString(),
    });
  }, [history, isHotType, isCategoryType, isSimilarType, type, category?.routeName]);

  const handleItemOnClick = useCallback((rewardItem, redeem = false) => {
    const { type: rewardItemType } = rewardItem;

    if (openRedeemModal) {
      return;
    }

    if (redeem && rewardItemType !== REWARD_TYPE.SEGMENTED_BY_PURCHASED_SKU) {
      setRedeemRewardItem(rewardItem);
      setOpenRedeemModal(true);
      return;
    }

    const relativePath = ROUTE_DEAL_STORE_REWARD_ID
      .replace(STORE_ID_ROUTE_PARAM, rewardItem.store?.id)
      .replace(REWARD_ID_ROUTE_PARAM, rewardItem.uid);

    history.push(relativePath);
  }, [history]);

  useEffect(() => {
    loadRewards();
    rewardsConfig();
  }, [type, rewardId, pageSize, category?.uid]);

  const emptyLoading = !showLoading && (isLoading || isLoadingConfig);
  const emptyState = showOnEmptyResults && isEmptyState;

  if (emptyLoading || emptyState) {
    return '';
  }

  return (
    <DashboardSection
      anchor={`${ANCHOR_DEALS}-${type}`}
      className="DealsPreview-DashboardSection__unlock"
      title={title}
      subTitle={subTitle}
      onClick={
        withCta && (alwaysShowCta || rewards.length > DEALS_LIMIT) ? handleOnClick : undefined
      }
      isLoading={isLoading || isLoadingConfig}
      error={error}
      noData={rewards.length ? undefined : 'No Deals available at the moment'}
      ctaTrackerProps={{
        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerViewAllButton),
        [POSTHOG_CAPTURE_ATTRIBUTES.SECTION]: type,
      }}
    >
      <div className="DealsPreview">
        <Slider
          type={isFeaturedType ? SLIDER_TYPES.DEFAULT : SLIDER_TYPES.OVERFLOW}
          autoplay={isFeaturedType}
          rewind={isFeaturedType}
          paginationDots={isFeaturedType}
          arrowsVerticalPosition={ARROWS_VERTICAL_POSITION.TOP}
          gap={32}
          slidesPerPage={slidesPerPage}
          slides={rewards.map((rewardItem, index) => ({
            key: `deal-preview-${rewardItem.uid}`,
            slide: (
              <RewardItem
                key={`slide-deal-${rewardItem.uid}`}
                index={index}
                rewardItem={rewardItem}
                onClick={handleItemOnClick}
                rewardProperties={rewardProperties}
                withCta={!isUnlockType}
                trackerProps={{
                  [POSTHOG_CAPTURE_ATTRIBUTES.SECTION]: type,
                  ...trackerProps,
                }}
              />
            ),
          }))}
        />
      </div>
      <RedeemModal
        rewardItem={redeemRewardItem}
        showModal={openRedeemModal}
        onClose={() => setOpenRedeemModal(false)}
      />
    </DashboardSection>
  );
};

export default DealsPreview;
