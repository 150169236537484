import { useEffect, useState, useRef } from 'react';

import { getSystemMessages } from '../service';
import { formatFullLocalDate } from '../dates';

const SYSTEM_MESSAGES_INTERVAL = 180_000;

const formatDateRange = (startDate, endDate) => {
  if (!startDate && !endDate) return '';

  if (startDate && endDate) {
    return ` from ${formatFullLocalDate(startDate)} to ${formatFullLocalDate(endDate)}`;
  }

  if (startDate) {
    return ` from ${formatFullLocalDate(startDate)}`;
  }

  return ` until ${formatFullLocalDate(endDate)}`;
};

const formatSystemMessage = (messageData) => {
  if (!messageData?.message) return null;

  const { message, startDate, endDate } = messageData;
  const dateRange = formatDateRange(startDate, endDate);

  return `${message}${dateRange}`;
};

const useGetSystemMessages = () => {
  const [systemMessage, setSystemMessage] = useState(null);
  const intervalRef = useRef(null);

  const messagesAreDifferent = (newData, currentData) => {
    if (!newData?.message) return false;
    if (!currentData?.message) return true;

    return JSON.stringify(currentData) !== JSON.stringify(newData);
  };

  const fetchMessage = () => {
    getSystemMessages()
      .then((response) => {
        const messageData = response.data?.message;
        if (messagesAreDifferent(messageData, systemMessage)) {
          setSystemMessage(messageData);
        }
      })
      .catch(console.log);
  };

  useEffect(() => {
    fetchMessage();
    intervalRef.current = setInterval(fetchMessage, SYSTEM_MESSAGES_INTERVAL);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [systemMessage]);

  const formattedMessage = systemMessage ? formatSystemMessage(systemMessage) : null;
  return formattedMessage;
};

export default useGetSystemMessages;
