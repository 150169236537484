import { useEffect, useState, useCallback, useRef } from 'react';
import classNames from 'classnames';
import { Box } from '@mui/material';

import { LIST_PAGE_SIZE } from '../../config/config';
import {
  ANCHOR_WALLET,
} from '../../config/routes';
import { REWARDS_ORDER_OPTIONS } from '../../config/rewards';
import { DEALS_AND_REWARDS_TYPES, STATUS_BUTTON_ARRAY } from '../../config/wallet';

import { getUserPerks } from '../../utils/service';
import StatusTabs from '../../global/StatusTabs';
import { isProd } from '../../../../config/config';
import DealsWallet from './DealsWallet';

import './Wallet.scss';

const DEFAULT_PAGE = 1;

const Wallet = () => {
  const timeoutRef = useRef(null);

  const [listData, setListData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(DEFAULT_PAGE);
  const [totalItems, setTotalItems] = useState(0);
  const [status, setStatus] = useState(DEALS_AND_REWARDS_TYPES.ACTIVE);

  const modifyStatus = useCallback(
    (rewardStatus) => {
      setStatus(rewardStatus);
      setPage(DEFAULT_PAGE);
    },
    [status],
  );

  const isTheLastElementInPage = useCallback(
    (listDataValue, pageValue) => (listDataValue?.length === 1 && pageValue > 1),
    [],
  );

  const loadRewards = useCallback(async (rewardStatus, pageValue) => {
    setIsLoading(true);

    const {
      data: rewardsData,
      error: rewardsDataError,
    } = await getUserPerks({
      pageSize: LIST_PAGE_SIZE,
      page: pageValue,
      orderBy: REWARDS_ORDER_OPTIONS.REDEEMED_AT_DEC,
      status: rewardStatus,
    });

    if (rewardsDataError) {
      setTotalItems(0);
      setListData([]);
      setPage(DEFAULT_PAGE);
      setIsLoading(false);
      return;
    }

    const {
      count,
      result: rewards,
    } = rewardsData;

    setTotalItems(count);
    setListData(rewards);
    setIsLoading(false);

    const mainElement = document.getElementById(ANCHOR_WALLET);

    if (mainElement) {
      timeoutRef.current = setTimeout(() => {
        mainElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, [page]);

  const handlePageSelection = useCallback((newPage) => {
    setPage(newPage);
  }, []);

  const onClickIsUsed = useCallback(() => {
    if (isTheLastElementInPage(listData, page)) {
      setPage((prevPage) => prevPage - 1);
      return;
    }
    loadRewards(status, page);
  }, [status, page, listData]);

  useEffect(() => {
    loadRewards(status, page);
  }, [page, status]);

  useEffect(() => () => timeoutRef?.current && clearTimeout(timeoutRef.current), []);

  return (
    <div id={ANCHOR_WALLET} className="Wallet">
      <div className="Wallet__content">
        <div className="Wallet__content--container">
          <Box
            className={classNames('Wallet__content--tabs', { hasEnvBanner: !isProd() })}
          >
            <StatusTabs
              tabArray={STATUS_BUTTON_ARRAY}
              onClickAction={modifyStatus}
              status={status}
            />
          </Box>
          <div className="Wallet__content--section">

            <DealsWallet
              isLoading={isLoading}
              status={status}
              listData={listData}
              paginatior={{ totalItems, page, handlePageSelection }}
              onClickIsUsed={onClickIsUsed}
            />

          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
