import { useMemo } from 'react';
import classNames from 'classnames';

import TrashiCoinLogo from '../../../../images/trashie-logo.webp';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import { WELCOME_PAY_ON_SHIP_VIDEO_URL } from '../../../../config/externalURL';
import {
  getNextButtonStyles,
} from '../../../../utils/tbbRegistration';

import './WelcomePayOnShip.scss';

const WelcomePayOnShip = ({
  handleOnClick,
  storeConfig,
  stepTrackerProps,
  isValidCode,
}) => {
  const {
    isTrashie: isTrashieStore,
    mainLogoSrc,
    registerTbbFlow,
  } = useMemo(() => storeConfig, [storeConfig]);

  const isTrashie = useMemo(() => (
    isTrashieStore || !mainLogoSrc
  ), [isTrashieStore, mainLogoSrc]);

  const storeLogo = useMemo(
    () => {
      if (isTrashie || !isValidCode) {
        return TrashiCoinLogo;
      }
      return mainLogoSrc;
    },
    [isTrashie, mainLogoSrc, isValidCode],
  );

  return (
    <div className="PayOnShipContainer">
      <div className="PayOnShipVideo">
        <video
          src={WELCOME_PAY_ON_SHIP_VIDEO_URL}
          autoPlay
          muted
          loop
          playsInline
        />
      </div>
      <div className="PayOnShipWelcome">
        <div className={classNames('PayOnShipWelcome__logo', { 'is-trashie': isTrashie })}>
          <img
            src={storeLogo}
            alt="store logo"
            style={registerTbbFlow?.landingPageStep?.logoStyleProperties}
          />
        </div>
        <div className="payonship-button-container">
          <Button
            type={BUTTON_TYPE.QUATERNARY}
            size={BUTTON_SIZE.LARGE}
            onClick={handleOnClick}
            style={getNextButtonStyles(storeConfig)}
            trackerProps={stepTrackerProps}
          >
            {registerTbbFlow?.landingPageStep?.button?.text ?? 'GET STARTED'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WelcomePayOnShip;
