import React, { useMemo } from 'react';
import classNames from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Splide, SplideTrack, SplideSlide } from '@splidejs/react-splide';

import { SIZE_MD, SIZE_SM, SIZE_XS, SIZE_XXL, isMobile } from '../../../../hooks/useBreakPoint';

// eslint-disable-next-line import/no-unresolved
import '@splidejs/react-splide/css';
import './Slider.scss';

export const SLIDER_TYPES = {
  DEFAULT: 'default',
  OVERFLOW: 'overflow',
};

export const ARROWS_VERTICAL_POSITION = {
  TOP: 'arrows-top',
  CENTER: 'arrows-center',
};

export const CAROUSEL_TYPES = {
  SLIDE: 'slide',
  FADE: 'fade',
};

const getPerPage = (slidesPerPage, mobileType) => {
  switch (mobileType) {
    case SIZE_XS:
    case SIZE_SM:
      return slidesPerPage > 2 ? Math.floor(slidesPerPage / 2) ?? 1 : slidesPerPage;
    default:
      return slidesPerPage;
  }
};

/*
  <Slider
    slides={
      [
        {
          key: 'key',
          slide: <Component />,
        },
        ...
      ]
    }
  />

- ONLY for slides without fixed width, need to pass 'slidesPerPage' prop,
  so slide's width is dynamically calculated.
  ie: slidesPerPage={4}
*/

const Slider = ({
  slides = [],
  slidesPerPage = 1,
  fixedPerPage = false,
  paginationArrows,
  paginationDots,
  type = SLIDER_TYPES.DEFAULT,
  carouselType = CAROUSEL_TYPES.SLIDE,
  rewind = true,
  autoplay = true,
  interval = 5000,
  gap = 16,
  arrowsVerticalPosition = ARROWS_VERTICAL_POSITION.CENTER,
  sliderId = null,
  pauseOnHover = true,
  trackPadding = true,
}) => {
  const mobileSM = isMobile([SIZE_SM, SIZE_XS]) && SIZE_SM;
  const mobileMD = isMobile([SIZE_MD]) && SIZE_MD;
  const mobileXXL = isMobile([SIZE_XXL]);

  const {
    mobileType,
    isOverflow,
    perPage,
  } = useMemo(() => ({
    mobileType: mobileSM || mobileMD || false,
    isOverflow: type === SLIDER_TYPES.OVERFLOW,
    perPage: fixedPerPage ? slidesPerPage : getPerPage(slidesPerPage, mobileSM),
  }), [mobileSM, type, fixedPerPage]);

  return (
    <Splide
      id={sliderId}
      className={classNames('Slider', arrowsVerticalPosition, {
        overflow: isOverflow || perPage === slides.length,
        hasDots: paginationDots,
        mobileXXL,
        trackPadding,
      })}
      hasTrack={false}
      options={{
        arrows: Boolean((paginationArrows || isOverflow) && !mobileType),
        pagination: Boolean(paginationDots),
        type: carouselType,
        rewind,
        autoplay,
        perPage,
        perMove: 1,
        interval,
        pauseOnHover,
        gap: mobileType ? gap / 2 : gap,
        ...(isOverflow && {
          omitEnd: true,
          focus: 0,
        }),
        ...((fixedPerPage || (perPage > 1 && mobileType)) && {
          fixedWidth: `calc(((100% + 1rem) / ${perPage}) - ${isOverflow ? '1.5' : '1'}rem)`,
        }),
      }}
      aria-labelledby="slider"
    >
      <SplideTrack>
        {slides.map(({ key, slide }) => (
          <SplideSlide key={key}>
            {slide}
          </SplideSlide>
        ))}
      </SplideTrack>
      <div className="splide__arrows">
        {paginationArrows && (
          <>
            <button type="button" className="splide__arrow splide__arrow--prev" aria-label="Prev" />
            <button type="button" className="splide__arrow splide__arrow--next" aria-label="Next" />
          </>
        )}
      </div>
      {paginationDots && (<ul className="splide__pagination" />)}
    </Splide>
  );
};

export default Slider;
