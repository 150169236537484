import { useCallback, useEffect, useState } from 'react';
import classNames from 'classnames';

import CircleChevronDown from '../SvgComponents/CircleChevronDown_svg';
import CircleChevronUp from '../SvgComponents/CircleChevronUp_svg';

import './CollapsableSection.scss';

export const ICON_SIZE = {
  DEFAULT: 'default',
  LARGE: 'large',
};

/*
  Uncontrolled:
    <CollapsableSection
      title={title}
      content={content}
    />

  Controlled:
    <CollapsableSection
      title={title}
      content={content}
      collapsed={collapsed}
      onChange={collapsed => handleOnChange(collapsed)}
    />
*/

const CollapsableSection = ({
  title,
  content,
  collapsed = true,
  onChange,
  className,
  contentMaxHeight = 1000,
  titleSize,
  iconSize = ICON_SIZE.DEFAULT,
  chevronColor,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(collapsed);

  const handleOnClick = useCallback(() => {
    setIsCollapsed(prev => {
      if (onChange) {
        onChange(!prev);
      }

      return !prev;
    });
  }, []);

  useEffect(() => {
    setIsCollapsed(collapsed);
  }, [collapsed]);

  return (
    <div className={classNames('CollapsableSection', className)}>
      <button
        type="button"
        className="CollapsableSection__button"
        onClick={handleOnClick}
      >
        <span className={classNames('CollapsableSection__title', titleSize)}>
          {title}
        </span>
        {isCollapsed ?
          (<CircleChevronDown className={classNames('CollapsableSection__icon', iconSize)} {...(chevronColor && { color: chevronColor })} />)
          : (<CircleChevronUp className={classNames('CollapsableSection__icon', iconSize)} {...(chevronColor && { color: chevronColor })} />)}
      </button>
      <div
        className={classNames('CollapsableSection__content', { 'CollapsableSection__content--collapsed': !isCollapsed })}
        style={isCollapsed ? { maxHeight: '0px' } : { maxHeight: `${contentMaxHeight}px`, marginTop: '16px' }}
      >
        {content}
      </div>
    </div>
  );
};

export default CollapsableSection;
