import classNames from 'classnames';
import './InfoSection.scss';

const InfoSection = ({
  titleText = '',
  subtitle,
  descriptionText = '',
  titleStyles,
  titleIcon,
  iconStyles,
}) => (
  <div className="InfoSection">
    <div className={classNames('InfoSection__title', titleStyles)}>
      {titleIcon && (
        <img
          className={classNames('InfoSection__icon', iconStyles)}
          src={titleIcon}
          alt="icon"
        />
      )}
      {titleText}
    </div>
    {subtitle && (
      <div className="InfoSection__subtitle">{subtitle}</div>
    )}
    {descriptionText && (
      <div className="InfoSection__description">{descriptionText}</div>
    )}
  </div>
);

export default InfoSection;
