import { useMemo } from 'react';
import classNames from 'classnames';

import Markdown from 'react-markdown';
import CollapsableSection from '../../../../../global/CollapsableSection';
import Card from '../../../../../global/Mui/Card';
import ShippingProviderLocations from '../ShippingProviderLocations';

import './ShippingProviderSelectItem.scss';

function ShippingProviderSelectItem({
  index,
  shippingProvider,
  selectedIndex,
  address,
  onSelect,
  storeConfig,
}) {
  const {
    promotion,
    logoURL,
    name,
    description,
    configuration: {
      details,
      locations,
      storesUrl,
    },
  } = useMemo(() => shippingProvider, [shippingProvider]);

  const isCurrentItemSelected = useMemo(() => (index === selectedIndex), [index, selectedIndex]);

  return (
    <>
      <Card
        onClick={() => onSelect(index, name)}
        className={classNames('ShippingProviderSelectItem', {
          selectedCardContainer: isCurrentItemSelected,
        })}
        style={{
          ...(isCurrentItemSelected && !storeConfig?.isTrashie && {
            'border-color': storeConfig?.registerTbbFlow?.nextButton?.bgColor,
          }),
        }}
      >
        <div className="ShippingProviderSelectItem__input">
          <input
            type="radio"
            className="radioButton"
            readOnly
            checked={isCurrentItemSelected}
            style={{
              ...(isCurrentItemSelected && !storeConfig?.isTrashie && {
                backgroundColor: storeConfig?.registerTbbFlow?.nextButton?.bgColor,
              }),
            }}
          />
        </div>

        <div className="ShippingProviderSelectItem__shipping-details">
          <div className="ShippingProviderSelectItem__header">
            <div className="shippingProviderDescriptionContainer">
              <div className="shipingProviderNameContainer">
                <p className="shipingProviderNameText">
                  {description}
                </p>
              </div>
              {promotion && (
                <p className="shipingProviderPromotionText">
                  {`Earn ${promotion.amount} TrashieCash™* with ${name}`}
                </p>
              )}
            </div>
          </div>
          {details && (
            <div className="ShippingProviderSelectItem__details">
              <Markdown className="ShippingProviderSelectItem__markdown">
                {details}
              </Markdown>
            </div>
          )}
          {
            storesUrl && (
              <a
                href={storesUrl}
                target="_blank"
                rel="noreferrer"
                className="ShippingProviderSelectItem__storesLink"
              >
                view stores
              </a>
            )
          }
          {locations?.enabled && (
            <CollapsableSection
              title={locations.description}
              titleSize="small"
              className="ShippingProviderSelectItem__collapsable"
              chevronColor="var(--trashie-ui-faded-black-60)"
              content={(
                <ShippingProviderLocations
                  locations={locations}
                  address={address}
                />
              )}
            />

          )}
        </div>
        <div
          className={classNames('ShippingProviderSelectItem__img', {
            'ShippingProviderSelectItem__img-storeUrl': storesUrl,
          })}
          style={{
            ...(!storeConfig?.isTrashie && {
              backgroundColor: storeConfig?.registerTbbFlow?.nextButton?.bgColorImg || 'var(--trashie-ui-faded-black-20)',
            }),
          }}
        >
          <img src={logoURL} alt={`${name} logo`} className="shippingProviderImage" />
        </div>

      </Card>
      {promotion && (
        <p className="shipingProviderPromotionnNote">
          * Gets awarded upon bag registration.
        </p>
      )}
    </>
  );
}

export default ShippingProviderSelectItem;
