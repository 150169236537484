import classNames from 'classnames';

import TCCoinSvg from '../SvgComponents/TCCoin_svg';
import { formatCurrencyNumber } from '../../utils/numbers';

import './TCPill.scss';

export const TC_PILL_TYPE = {
  CURRENT: 'current',
  EARN: 'earn',
};

const TCPill = ({
  balance,
  type = TC_PILL_TYPE.CURRENT,
}) => {
  const isEarn = type === TC_PILL_TYPE.EARN;

  return (
    <div className={classNames('TCPill', { earn: isEarn })}>
      <p>
        {isEarn && '+'}
        {formatCurrencyNumber(
          balance,
          new Intl.NumberFormat('en-US', {
            style: 'decimal',
            ...(isEarn
              ? { maximumFractionDigits: 0 }
              : { minimumFractionDigits: 2 }
            ),
          }),
        )}
      </p>
      <TCCoinSvg width="14" height="14" color="var(--trashie-primary-code-blue)" />
    </div>
  );
};

export default TCPill;
