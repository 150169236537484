import { useHistory } from 'react-router-dom';

import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import { TAKE_BACK_BAG_URL } from '../../../../config/externalURL';
import { ROUTE_DEALS } from '../../../../config/routes';
import DealsPreview from '../DealsPreview';
import { isMobile } from '../../../../../../hooks/useBreakPoint';
import ShopTakeBackDesktop from '../../../../images/shop-take-back-desktop.webp';
import ShopTakeBackMobile from '../../../../images/shop-take-back-mobile.webp';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../../config/tracker';
import './DealsPreviewBanner.scss';
import { REWARD_PREVIEW_TYPES } from '../../../../config/rewards';

const {
  dashboard: {
    unlock: {
      shopTakeBackBag: trackerShopTakeBackBag,
    },
  },
} = TRACKER_IDS;

const DealsPreviewBanner = ({
  trackerProps = {},
}) => {
  const history = useHistory();
  const mobile = isMobile();

  return (
    <div className="DealsPreviewBanner">
      <div className="DealsPreviewBanner__container">
        <div
          className="DealsPreviewBanner__container--panel"
          style={{ backgroundImage: `url(${mobile ? ShopTakeBackMobile : ShopTakeBackDesktop})` }}
        >
          <span className="DealsPreviewBanner__container--panel-message">
            SHOP TAKE BACK AND UNLOCK 100s OF DEALS
          </span>
          {!mobile && (
            <DealsPreviewBannerShopButton
              className="DealsPreviewBanner__container--cta"
            />
          )}
        </div>
        <div className="DealsPreviewBanner__container--content">
          <DealsPreview
            title={mobile ? undefined : 'All-access Deals'}
            type={REWARD_PREVIEW_TYPES.UNLOCK}
            withCta={!mobile}
            trackerProps={trackerProps}
          />
        </div>
        {mobile && (
          <div className="DealsPreviewBanner__container--buttons">
            <DealsPreviewBannerShopButton
              className="DealsPreviewBanner__container--cta"
            />
            <Button
              size={BUTTON_SIZE.LARGE}
              type={BUTTON_TYPE.LINK_QUATERNARY}
              onClick={() => { history.push(ROUTE_DEALS); }}
            >
              BROWSE ALL DEALS
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const DealsPreviewBannerShopButton = ({ className }) => {
  const trackerProps = {
    [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerShopTakeBackBag),
    [POSTHOG_CAPTURE_ATTRIBUTES.TBB_SHOP_LINK]: 'true',
  };

  return (
    <Button
      size={BUTTON_SIZE.LARGE}
      type={BUTTON_TYPE.QUATERNARY}
      onClick={() => { window.location.href = TAKE_BACK_BAG_URL; }}
      className={className}
      trackerProps={trackerProps}
    >
      SHOP TAKE BACK
    </Button>
  );
};

export default DealsPreviewBanner;
