import { useCallback, useState } from 'react';
import { FormCheck, Tooltip, OverlayTrigger } from 'react-bootstrap';

import { PRIVACY_POLICY_URL, TERMS_OF_SERVICE_URL } from '../../../config/externalURL';
import { getActionType, getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import { BUTTON_SIZE, BUTTON_TYPE, Button } from '../../../global/Button';
import { Cover, COVER_TYPE } from '../../../global/Rewards';
import RewardSummary from './RewardSummary';

import './RewardContent.scss';
import { isRewardDeal } from '../../../utils/rewards';

const {
  rewards: {
    singleMerchantPage: {
      confirmButton: trackerConfirmButton,
    },
  },
} = TRACKER_IDS;

const ModalContentConfirm = ({
  rewardItem,
  onSubmit,
  onClose,
  rewardProperties,
}) => {
  const [isButtonEnabled, setIsButtonEnabled] = useState(true);
  const handleOnSubmit = useCallback(() => {
    onSubmit(rewardItem.uid);
  }, [rewardItem, onSubmit]);

  return (
    <div className="body-style confirm">
      <div className="body-top">
        <Cover
          rewardItem={rewardItem}
          type={COVER_TYPE.REDEEM}
          backgroundEnabled={false}
          rewardProperties={rewardProperties}
        />
      </div>
      <div className="body-bottom">
        <div className="content">
          <RewardSummary
            rewardItem={rewardItem}
            title="ONE LAST CHECK"
          />
        </div>
        <div className="action-container">
          <p className="title">
            ALL GOOD?
          </p>
          <FormCheck
            className="terms-of-service-checkbox"
            onChange={() => setIsButtonEnabled(!isButtonEnabled)}
            type="checkbox"
            id="reward-confirm-button"
            label={(
              <span>
                Yes, and I agree to the
                {' '}
                <a className="link" href={PRIVACY_POLICY_URL} target="_blank" rel="noopener noreferrer">
                  Privacy Policy
                </a>
                {' '}
                and
                {' '}
                <a className="link" href={TERMS_OF_SERVICE_URL} target="_blank" rel="noopener noreferrer">
                  Terms of Service.
                </a>
              </span>
            )}
          />
          <OverlayTrigger
            placement="top"
            overlay={isButtonEnabled ? (
              <Tooltip>
                Please tick the checkbox to confirm
              </Tooltip>
            ) : <div />}
          >
            <div className="confirm-button-container">
              <Button
                className="confirm-button-dialog"
                disabled={isButtonEnabled}
                type={BUTTON_TYPE.PRIMARY}
                size={BUTTON_SIZE.LARGE}
                onClick={handleOnSubmit}
                trackerProps={{
                  [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerConfirmButton),
                  [POSTHOG_CAPTURE_ATTRIBUTES.ACTION_ID]: getActionType(trackerConfirmButton),
                  [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_ID]: rewardItem.uid,
                  [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_NAME]: rewardItem.name,
                  [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_IS_DEAL]: isRewardDeal(rewardItem),
                }}
              >
                CONTINUE
              </Button>
            </div>
          </OverlayTrigger>
          <div className="cancel-option" onClick={() => onClose()} aria-hidden>
            GO BACK
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalContentConfirm;
