import { useState, useCallback } from 'react';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../../global/Button';
import ShippingProviderLocations from '../../SelectShippingProviderView/ShippingProviderLocations';
import Dialog from '../../../../../global/Mui/Dialog';
import { getNextButtonStyles, getOptionButtonStyles } from '../../../../../utils/tbbRegistration';
import './ReadyToShip.scss';

export default function ReadyToShip({
  shippingMethod,
  address,
  onClick,
  stepTrackerProps,
  storeConfig,
}) {
  const {
    shippingAndRewardTitle,
    description,
    icon,
    ctaLabel,
    ctaLabel2,
    findLocation,
    locations,
  } = shippingMethod?.configuration ?? {};
  const [showLocations, setShowLocations] = useState(false);

  const handleLocationsClick = useCallback(() => {
    if (locations?.enabled) {
      setShowLocations(true);
    }

    if (findLocation) {
      window.open(findLocation, '_blank');
    }
  }, [locations, findLocation]);

  return (
    <div className="ReadyToShip">
      <h1 className="ReadyToShip__title">{shippingAndRewardTitle}</h1>
      <p className="ReadyToShip__description">{description}</p>
      <Button
        type={BUTTON_TYPE.QUINARY}
        size={BUTTON_SIZE.LARGE}
        onClick={onClick}
        trackerProps={stepTrackerProps}
        style={getNextButtonStyles(storeConfig)}
      >
        <img src={icon} alt="ups" className="ReadyToShip__carrier-icon" />
        {ctaLabel}
      </Button>
      {(findLocation || locations?.enabled) && (
        <Button
          type={BUTTON_TYPE.LINK}
          size={BUTTON_SIZE.SMALL}
          onClick={handleLocationsClick}
          className="ReadyToShip__secondary-cta"
          style={getOptionButtonStyles(storeConfig)}
        >
          {ctaLabel2}
        </Button>
      )}
      <Dialog open={showLocations} onClose={() => setShowLocations(false)}>
        <ShippingProviderLocations locations={locations} address={address} logo={icon} inDialog />
      </Dialog>
    </div>
  );
}
