/* eslint-disable react/jsx-indent */
import React from 'react';

const CircleChevronUp = ({ color = '#292829', size = 20 }) => (
    <svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon / chevron-up-circle">
            <g id="icon">
                <path d="M12 2.35733C6.486 2.35733 2 6.84333 2 12.3573C2 17.8713 6.486 22.3573 12 22.3573C17.514 22.3573 22 17.8713 22 12.3573C22 6.84333 17.514 2.35733 12 2.35733ZM12 20.3573C7.589 20.3573 4 16.7683 4 12.3573C4 7.94633 7.589 4.35733 12 4.35733C16.411 4.35733 20 7.94633 20 12.3573C20 16.7683 16.411 20.3573 12 20.3573Z" fill={color} />
                <path d="M6.29303 13.6503L7.70703 15.0643L12 10.7713L16.293 15.0643L17.707 13.6503L12 7.94333L6.29303 13.6503Z" fill={color} />
            </g>
        </g>
    </svg>
);

export default CircleChevronUp;
