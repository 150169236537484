import { useCallback, useEffect, useState } from 'react';

import { getConfigurations } from '../components/trashie/utils/service';
import {
  TBB_PAYMENT_CONFIG_ITEMS,
  TBB_PAYMENT_CONFIG_ITEMS_CONTENT,
} from '../components/trashie/utils/tbbRegistration';

const useTbbPaymentConfig = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [tbbPaymentConfig, setTbbPaymentConfig] = useState({});

  const loadTbbPaymentConfig = useCallback(async () => {
    setIsLoading(true);

    const { value, error } = await getConfigurations('tbbPaymentConfig');

    if (error) {
      setTbbPaymentConfig({});
      setIsLoading(false);
      return;
    }

    setTbbPaymentConfig({
      ...value,
      items: (value.items || [])
        .filter(item => item.enabled && TBB_PAYMENT_CONFIG_ITEMS[item.id])
        .map(item => ({
          ...item,
          content: TBB_PAYMENT_CONFIG_ITEMS_CONTENT[item.id],
        })),
    });
    setIsLoading(false);
  }, []);

  useEffect(() => {
    loadTbbPaymentConfig();
  }, []);

  return {
    isLoading,
    tbbPaymentConfig,
  };
};

export default useTbbPaymentConfig;
