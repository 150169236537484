import classNames from 'classnames';
import './DealCard.scss';

function getCardLogo(logoUrl) {
  return <img src={logoUrl} alt="logo" className="DealCard__container--logo" />;
}

const DealCard = ({
  dealItem,
  isCircleVariant = false,
  containerClassName,
}) => {
  const { store } = dealItem || {};

  return (
    <div className={classNames('DealCard', { 'circle-variant': isCircleVariant })}>
      <div
        className={classNames('DealCard__container', containerClassName)}
        style={{
          background: `linear-gradient(0deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.35) 100%), url(${store?.imgUrl}) lightgray 50% / cover no-repeat`,
        }}
      >
        {isCircleVariant ? (
          <div
            className="DealCard__container--circle-wrapper"
            style={{ background: store.cardBgColor || 'white' }}
          >
            {getCardLogo(store?.logoUrl)}
          </div>
        ) : (
          getCardLogo(store?.logoUrl)
        )}
      </div>
    </div>
  );
};

export default DealCard;
