import { useCallback, useEffect, useState } from 'react';
import { useLocation, Link } from 'react-router-dom';

import classNames from 'classnames';
import Header from '../../global/Header';
import Footer from '../../global/Footer';
import { isTrackedPath, isTrashieContainerPath, isFooterPath } from '../../utils/routes';
import { DOWNLOAD_EXTENSION_URL, TRASHIE_WEBSITE_URL } from '../../config/externalURL';
import RightArrow from '../../images/chevron-down.svg';

import { EVENT_STREAM_APP_TYPES, EVENT_STREAM_EVENT_TYPES } from '../../config/service';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import LoadingBar from '../../global/LoadingBar';
import { getConfigurations, sendEventStream } from '../../utils/service';

import InformativeBanner from '../../global/InformativeBanner/InformativeBanner';
import { isMobile } from '../../../../hooks/useBreakPoint';
import { TRASHIE_BASE_URL, isProd } from '../../../../config/config';
import { useAlert } from '../../../providers/AlertProvider';
import { signOut } from '../../../../utils/auth';
import { setDocumentTitle } from '../../utils/utils';
import useGetSystemMessages from '../../utils/hooks/useGetSystemMessages';

import Style from './TrashieContainer.module.scss';

const {
  main,
  content,
  hasMobileFooter,
  loader,
  arrow,
  learnMore,
  button,
} = Style;

const TrashieContainer = ({ children }) => {
  const { pathname } = useLocation();
  const { isLoading, isSignedIn, user, userData } = useFirebaseAuth();
  const setAlert = useAlert();
  const isMobileSize = isMobile();

  const [userHasExtensionInstalled, setUserHasExtensionInstalled] = useState(true);
  const [isReady, setIsReady] = useState(false);

  const systemMessage = useGetSystemMessages();

  const handleEventStream = useCallback(async (data) => {
    await sendEventStream({
      event: EVENT_STREAM_EVENT_TYPES.USER_LAST_VISIT,
      application: EVENT_STREAM_APP_TYPES.TRASHIE,
      data: {
        url: window.location.href,
      },
    });

    setUserHasExtensionInstalled(data?.isExtensionInstalled);
  }, []);

  const validateNonProductionEnvWhitelistedUsers = useCallback(async () => {
    if (isProd()) {
      setIsReady(true);
      return;
    }

    const { value, error } = await getConfigurations('nonProductionEnvWhitelistedUsers');

    if (error) {
      setAlert({
        type: 'alert',
        message: error,
        error,
      });
      return;
    }

    if (!(value.emails || []).includes(user?.email) &&
      !(value.domains || []).some(domain => (user?.email || '').includes(domain))
    ) {
      await signOut();
      window.location.href = TRASHIE_BASE_URL.production;
      return;
    }

    setIsReady(true);
  }, [user]);

  useEffect(() => {
    if (isTrackedPath(pathname) && isSignedIn && userData) {
      handleEventStream(userData);
    }
  }, [isSignedIn, userData, pathname]);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    if (isSignedIn) {
      validateNonProductionEnvWhitelistedUsers();
    } else {
      setIsReady(true);
    }
  }, [isLoading, isSignedIn]);

  useEffect(() => {
    setDocumentTitle();
  }, [pathname]);

  if (!isTrashieContainerPath(pathname)) {
    return null;
  }

  const showInfoBanner = !isSignedIn || !userHasExtensionInstalled;

  return (
    <div className={main}>
      <Header isBannerVisible={showInfoBanner || !!systemMessage} />
      <div className={classNames(content, {
        [hasMobileFooter]: isMobileSize && isFooterPath(pathname),
      })}
      >
        {isLoading || !isReady ? (
          <div className={loader}>
            <LoadingBar />
          </div>
        ) : (
          <>
            {systemMessage ? (
              <InformativeBanner
                isNotification
                mainText={systemMessage}
              />
            ) : showInfoBanner && (
              <InformativeBanner
                mainText={isMobileSize ? 'ADD THE CHROME EXTENSION TO EARN TRASHIECASH™' :
                  'ADD THE CHROME EXTENSION TO EARN TRASHIECASH™ AS YOU SHOP BRANDS YOU LOVE.'}
                ctaChildren={isMobileSize ?
                  (
                    <Link target="_blank" to={{ pathname: TRASHIE_WEBSITE_URL }} className={learnMore}>
                      <img src={RightArrow} className={arrow} alt="arrow" />
                    </Link>
                  ) : (
                    <>
                      <Link target="_blank" to={{ pathname: TRASHIE_WEBSITE_URL }} className={learnMore}>
                        LEARN MORE
                      </Link>
                      <Link target="_blank" to={{ pathname: DOWNLOAD_EXTENSION_URL }} className={button}>
                        ADD TO CHROME
                      </Link>
                    </>
                  )}
              />
            )}
            {children}
          </>
        )}
      </div>
      {(isMobileSize && !isFooterPath(pathname)) ? null : <Footer />}

    </div>
  );
};

export default TrashieContainer;
