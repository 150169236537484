import { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import DashboardSection from '../../../global/DashboardSection';
import { getGenericError } from '../../../utils/errors';
import { getConfigurations, getRewards } from '../../../utils/service';
import {
  ANCHOR_REWARDS,
  REWARD_ID_ROUTE_PARAM,
  ROUTE_REWARDS,
  ROUTE_REWARDS_STORE_REWARD_ID,
  STORE_ID_ROUTE_PARAM,
  CATEGORY_SEARCH_PARAM,
} from '../../../config/routes';
import { getTrackerId, POSTHOG_CAPTURE_ATTRIBUTES, TRACKER_IDS } from '../../../config/tracker';
import RewardItem from '../RewardItem';
import Slider, { ARROWS_VERTICAL_POSITION, SLIDER_TYPES } from '../../../global/Slider';

import './RewardsPreview.scss';
import { FLAGS, getRewardFilters } from '../utils';
import { REWARD_PREVIEW_TYPES } from '../../../config/rewards';

const PREVIEW_PAGE_SIZE = 10;

const {
  dashboard: {
    trashiecashRewards: {
      viewAllButton: trackerViewAllButton,
    },
  },
} = TRACKER_IDS;

const REWARD_LIMIT = 4;

const RewardsPreview = ({
  title = 'Premium Rewards',
  type = REWARD_PREVIEW_TYPES.DEFAULT,
  rewardId = null,
  showLoading = true,
  showOnEmptyResults = false,
}) => {
  const history = useHistory();
  const [rewards, setRewards] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [rewardProperties, setRewardProperties] = useState({});
  const [isLoadingConfig, setIsLoadingConfig] = useState(true);
  const [isEmptyState, setIsEmptyState] = useState(false);

  const loadRewards = useCallback(async () => {
    setIsLoading(true);
    const {
      data,
      error: rewardsError,
    } = await getRewards({
      pageSize: PREVIEW_PAGE_SIZE,
      page: 1,
      type,
      ...getRewardFilters({ type, rewardId }),
    });

    if (rewardsError) {
      setIsEmptyState(true);
      setRewards([]);
      setError(rewardsError.message || getGenericError());
      setIsLoading(false);
      return;
    }

    setRewards(data.rewards);
    setIsEmptyState(!data.rewards.length);
    setError('');
    setIsLoading(false);
  }, [type, rewardId]);

  const rewardsConfig = useCallback(async () => {
    setIsLoadingConfig(true);
    const { value, error: configError } = await getConfigurations('rewardProperties');
    if (!configError) {
      setRewardProperties(value);
    }
    setIsLoadingConfig(false);
  }, [rewardId, type]);

  const handleOnClick = useCallback((rewardItem) => {
    const relativePath = ROUTE_REWARDS_STORE_REWARD_ID
      .replace(STORE_ID_ROUTE_PARAM, rewardItem.store?.id)
      .replace(REWARD_ID_ROUTE_PARAM, rewardItem.uid);

    history.push(relativePath);
  }, [history]);

  const handleRedirectOnClick = useCallback(() => {
    let categoryParam = FLAGS.ALL;
    if (type === REWARD_PREVIEW_TYPES.EXCLUSIVE) {
      categoryParam = FLAGS.IS_EXCLUSIVE;
    }

    history.push({
      pathname: ROUTE_REWARDS,
      search: new URLSearchParams({ [CATEGORY_SEARCH_PARAM]: categoryParam }).toString(),
    });
  }, [history, type]);

  useEffect(() => {
    loadRewards();
    rewardsConfig();
  }, [rewardId, type]);

  const emptyLoading = !showLoading && (isLoading || isLoadingConfig);
  const emptyState = showOnEmptyResults && isEmptyState;

  if (emptyLoading || emptyState) {
    return '';
  }

  return (
    <DashboardSection
      anchor={ANCHOR_REWARDS}
      title={title}
      subTitle={type === REWARD_PREVIEW_TYPES.DEFAULT ? 'You’ve earned it — redeem TrashieCash™ for something awesome.' : undefined}
      onClick={
        rewards.length > REWARD_LIMIT ?
          () => handleRedirectOnClick()
          : undefined
      }
      isLoading={isLoading || isLoadingConfig}
      error={error}
      noData={rewards.length ? undefined : 'No Rewards available at the moment'}
      ctaTrackerProps={{
        [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(trackerViewAllButton),
      }}
    >
      <div className="RewardsPreview">
        <Slider
          type={SLIDER_TYPES.OVERFLOW}
          autoplay={false}
          rewind={false}
          paginationDots={false}
          arrowsVerticalPosition={ARROWS_VERTICAL_POSITION.TOP}
          gap={32}
          slidesPerPage={4}
          slides={rewards.map((rewardItem, index) => ({
            key: `reward-preview-${rewardItem.uid}`,
            slide: (
              <RewardItem
                key={`slide-reward-${rewardItem.uid}`}
                index={index}
                rewardItem={rewardItem}
                onClick={handleOnClick}
                rewardProperties={rewardProperties}
                trackerProps={{
                  [POSTHOG_CAPTURE_ATTRIBUTES.SECTION]: type,
                }}
              />
            ),
          }))}
        />
      </div>
    </DashboardSection>
  );
};

export default RewardsPreview;
