import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import LoadingBar from '../../../../../global/LoadingBar';
import CircularRewardsCarousel from '../../../../../global/CircularRewardsCarousel';
import { getRewardsExclusive } from '../../../../../utils/service';
import {
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  TRACKER_IDS,
} from '../../../../../config/tracker';
import {
  REWARD_ID_ROUTE_PARAM,
  ROUTE_REWARDS_STORE_REWARD_ID,
  ROUTE_DEAL_STORE_REWARD_ID,
  STORE_ID_ROUTE_PARAM,
} from '../../../../../config/routes';
import { isRewardDeal } from '../../../../../utils/rewards';
import './ExclusiveRewardsCarousel.scss';

const {
  tbb: {
    lastStep: { exclusiveReward: exclusiveRewardItem },
  },
} = TRACKER_IDS;

export default function ExclusiveRewardsCarousel({ code }) {
  const history = useHistory();

  const [rewards, setRewards] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(async () => {
    setIsLoading(true);

    const { data, error: rewardsError } = await getRewardsExclusive({
      redemptionCode: code,
      page: 1,
      pageSize: 10,
    });

    if (rewardsError) {
      setError(rewardsError);
    } else {
      setRewards(data.rewards);
      setError('');
    }

    setIsLoading(false);
  }, [code]);

  const onRewardClick = useCallback(
    (reward) => {
      let relativePath = ROUTE_REWARDS_STORE_REWARD_ID;
      console.log(reward);

      const isDeal = isRewardDeal(reward);
      if (isDeal) {
        relativePath = ROUTE_DEAL_STORE_REWARD_ID;
      }

      relativePath = relativePath
        .replace(STORE_ID_ROUTE_PARAM, reward.store.id)
        .replace(REWARD_ID_ROUTE_PARAM, reward.uid);

      history.push(relativePath);
    },
    [history],
  );

  const getTrackerProps = (reward) => ({
    [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(exclusiveRewardItem),
    [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_ID]: reward.uid,
    [POSTHOG_CAPTURE_ATTRIBUTES.REWARD_NAME]: reward.name,
    [POSTHOG_CAPTURE_ATTRIBUTES.STORE_ID]: reward.store.id,
    [POSTHOG_CAPTURE_ATTRIBUTES.STORE_NAME]: reward.store.title,
  });

  if (isLoading) {
    return <LoadingBar />;
  }

  if (error || rewards.length === 0) {
    return null;
  }

  return (
    <div className="ExclusiveRewardsCarousel">
      <h2 className="ExclusiveRewardsCarousel__title">Snag your limited-time deals now!</h2>
      <CircularRewardsCarousel
        rewards={rewards}
        onClick={onRewardClick}
        getTrackerProps={getTrackerProps}
      />
    </div>
  );
}
