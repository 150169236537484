import Markdown from 'react-markdown';

import './TermsDisclaimer.scss';

const TermsDisclaimer = ({ prefix, termsTitle, terms, ctaText, onHandleClick }) => (
  <div className="TermsDisclaimer">
    {prefix && prefix}
    {terms && (
      <>
        <div className="TermsDisclaimer__content">
          <div className="TermsDisclaimer__content--title">{termsTitle}</div>
          <div className="TermsDisclaimer__content--body">
            <Markdown className="markdown markdown--wrapper TermsDisclaimer__content--body--text">
              {terms}
            </Markdown>
          </div>
        </div>
        <div className="TermsDisclaimer__cta" onClick={() => onHandleClick()} aria-hidden>
          {ctaText}
        </div>
      </>
    )}
  </div>
);

export default TermsDisclaimer;
