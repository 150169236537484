import * as React from 'react';
import classNames from 'classnames';

import Style from './InformativeBanner.module.scss';

const {
  main,
  container,
  notification,
  text,
} = Style;

const InformativeBanner = ({ mainText, ctaChildren, isNotification }) => {
  const mainStyle = isNotification ? classNames(main, notification) : main;
  return (
    <div className={mainStyle}>
      <div className={container}>
        <p className={text}>
          {mainText}
        </p>
        {ctaChildren}
      </div>
    </div>
  );
};

export default InformativeBanner;
