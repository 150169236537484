import { Box, Button } from '@mui/material';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';
import GhostIcon from '../../../images/ghost-icon.svg';
import { isProd } from '../../../../../config/config';
import RewardsList from '../../Rewards/RewardsList';
import GiftCardsList from '../../GiftCards/GiftCardsList';
import LoadingBar from '../../../global/LoadingBar';
import { BUTTON_SIZE, BUTTON_TYPE } from '../../../global/Button';
import { POSTHOG_CAPTURE_ATTRIBUTES } from '../../../config/tracker';

import './RewardsResults.scss';

export const RESULTS_TYPE = Object.freeze({
  DEALS_LIST: 'deals-list',
  DEALS_SEARCH: 'deals-search',
  REWARDS_LIST: 'rewards-list',
  REWARDS_SEARCH: 'rewards-search',
  GIFT_CARDS_SEARCH: 'gift-cards-search',
});

const getSearchNoMatchesMessage = (resultsType) => {
  if (resultsType === RESULTS_TYPE.GIFT_CARDS_SEARCH) {
    return {
      noMatchesTitle: 'No gift cards found',
      noMatchesSubtitle: 'Try a different brand name or browse all gift cards.',
    };
  }

  if (resultsType === RESULTS_TYPE.REWARDS_SEARCH) {
    return {
      noMatchesTitle: 'No exact matches',
      noMatchesSubtitle: 'Try a different brand name or browse all rewards.',
    };
  }

  return {
    noMatchesTitle: 'No exact matches',
    noMatchesSubtitle: 'Try a different brand name or browse all deals.',
  };
};

const RewardsResults = ({
  resultsType,
  listElementId,
  isLoadingRewards,
  isLoadingConfig,
  categoryRewards,
  rewardProperties,
  loadCategoryRewards,
  selectedCategory,
  sortingSelected,
  filterSelected,
  categoryRewardsTotal,
  handleOnClick,
  rewardsError,
  noResultsTitle,
  noResultsSubtitle,
  noResultsCtaText,
  noResultsCtaOnClick,
  firstLoad,
  searchedText,
}) => {
  const isTextSearch = [
    RESULTS_TYPE.DEALS_SEARCH,
    RESULTS_TYPE.REWARDS_SEARCH,
    RESULTS_TYPE.GIFT_CARDS_SEARCH,
  ].includes(resultsType);

  const getZeroState = () => {
    if (!isTextSearch) {
      return (
        <div className="RewardsResults__content--empty">
          <img src={GhostIcon} alt="ghost icon" />
          <div className="RewardsResults__content--empty-title">
            {noResultsTitle}
          </div>
          <div className="RewardsResults__content--empty-subtitle">
            {noResultsSubtitle}
          </div>
          <Button
            size={BUTTON_SIZE.LARGE}
            type={BUTTON_TYPE.QUATERNARY}
            onClick={noResultsCtaOnClick}
          >
            {noResultsCtaText}
          </Button>
        </div>
      );
    }

    if (isTextSearch && !firstLoad) {
      const { noMatchesTitle, noMatchesSubtitle } = getSearchNoMatchesMessage(resultsType);

      return (
        <div className="RewardsResults__zero-state-container">
          <p className="RewardsResults__zero-state-container__title">{noMatchesTitle}</p>
          <p className="RewardsResults__zero-state-container__subtitle">{noMatchesSubtitle}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <Box
      id={listElementId}
      className={classNames('RewardsResults__content--pane', { hasEnvBanner: !isProd(), isZeroState: categoryRewards.length === 0 })}
    >
      {!isLoadingRewards && !isLoadingConfig && categoryRewards.length === 0 ? (
        getZeroState()
      ) : (
        <InfiniteScroll
          dataLength={categoryRewards.length}
          next={
            () => loadCategoryRewards(
              selectedCategory,
              sortingSelected,
              filterSelected,
              [],
              searchedText,
            )
          }
          hasMore={categoryRewards.length < categoryRewardsTotal}
          className="RewardsResults__content--scroll"
        >
          {[RESULTS_TYPE.GIFT_CARDS, RESULTS_TYPE.GIFT_CARDS_SEARCH].includes(resultsType) ? (
            <GiftCardsList
              className="RewardsResults__content--gift-cards"
              giftCardsList={categoryRewards}
              onClick={handleOnClick}
            />
          ) : (
            <RewardsList
              rewardsList={categoryRewards}
              onClick={handleOnClick}
              rewardProperties={rewardProperties}
              trackerProps={{
                [POSTHOG_CAPTURE_ATTRIBUTES.SECTION]: resultsType,
                ...(selectedCategory && {
                  [POSTHOG_CAPTURE_ATTRIBUTES.CATEGORY]: selectedCategory,
                }),
              }}
            />
          )}
        </InfiniteScroll>
      )}
      {(isLoadingRewards || isLoadingConfig) && (
      <LoadingBar className="RewardsResults__content--loader" />
      )}
      {rewardsError && rewardsError}
    </Box>
  );
};

export default RewardsResults;
