import { useCallback, useEffect, useState, useMemo } from 'react';

import InfoSection from '../../../../global/InfoSection';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import ShippingProviderSelectItem from './ShippingProviderSelectItem';
import { fetchShippingProviders } from '../../../../utils/service';
import LoadingBar from '../../../../global/LoadingBar';
import {
  getNextButtonStyles,
  getOptionButtonStyles,
} from '../../../../utils/tbbRegistration';
import { POSTHOG_PROPERTIES } from '../../../../config/tracker';
import EditIcon from '../../../../images/icon-edit-alt.webp';

import './SelectShippingProviderView.scss';

const DEFAULT_ITEMS_SHOWN = 2;
const BUTTON_LABEL = 'LET’S GOOOO!';
const ADDRESS_LABEL = 'Your return address';

const SelectShippingProviderView = ({
  setShippingMethod,
  storeConfig,
  onReturnToAddress,
  onSubmitShippingMethod,
  selectedShippingMethodIndex,
  setSelectedShippingMethodIndex,
  address,
  onError,
  recyclingProductType,
  stepTrackerProps,
  descriptionText,
  isExpiredLabel,
}) => {
  const {
    address1,
    address2,
    city,
    provinceCode,
    zip,
    firstName,
    lastName,
  } = address;
  const [shippingProviders, setShippingProviders] = useState([]);
  const [collapsed, setCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const getShippingProviders = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error,
    } = await fetchShippingProviders({
      storeUid: storeConfig.uid,
      storeId: storeConfig.id,
      recyclingProductType,
    });

    if (error) {
      onError();
      setIsLoading(false);
      return;
    }

    const shippingCustomSort = storeConfig?.registerTbbFlow?.shippingCustomSort || {};

    const filteredShippingProviders = isExpiredLabel ? data.filter((
      provider,
    ) => !provider.configuration?.nonRegenerableLabel) : data;

    const sortedShippingProviders = filteredShippingProviders.sort((a, b) => {
      const indexA = Object.values(shippingCustomSort).indexOf(a.uid);
      const indexB = Object.values(shippingCustomSort).indexOf(b.uid);

      if (indexA !== -1 && indexB !== -1) {
        return indexA - indexB;
      }

      return a.order - b.order;
    });
    setShippingProviders(sortedShippingProviders);
    setShippingMethod(sortedShippingProviders[selectedShippingMethodIndex]);
    setIsLoading(false);
  });

  const onSelectShippingProvider = useCallback((index) => {
    setSelectedShippingMethodIndex(index);
    setShippingMethod(shippingProviders[index]);
  }, [shippingProviders]);

  useEffect(() => {
    getShippingProviders();
  }, []);

  const itemsToShow = useMemo(() => (
    storeConfig?.registerTbbFlow?.shippingProviderItemsShown || DEFAULT_ITEMS_SHOWN
  ));

  return (
    <div className="SelectShippingProviderView">
      <InfoSection
        titleStyles="textTrasformNone"
        titleText="Shipping method"
        descriptionText={descriptionText}
      />
      {isLoading ? (
        <LoadingBar />
      ) : (
        <>
          <div className="SelectShippingProviderView__items">
            {shippingProviders.slice(0, itemsToShow).map((shippingProvider, index) => (
              <ShippingProviderSelectItem
                key={shippingProvider.uid}
                index={index}
                shippingProvider={shippingProvider}
                selectedIndex={selectedShippingMethodIndex}
                address={address}
                onSelect={onSelectShippingProvider}
                storeConfig={storeConfig}
              />
            ))}
            {!collapsed && shippingProviders.slice(itemsToShow).map((shippingProvider, index) => (
              <ShippingProviderSelectItem
                key={shippingProvider.uid}
                index={index + itemsToShow}
                shippingProvider={shippingProvider}
                selectedIndex={selectedShippingMethodIndex}
                address={address}
                onSelect={onSelectShippingProvider}
                storeConfig={storeConfig}
              />
            ))}
          </div>
          {shippingProviders.length > itemsToShow && collapsed && (
            <Button
              type={BUTTON_TYPE.LINK_QUINARY}
              size={BUTTON_SIZE.LARGE}
              onClick={() => setCollapsed(false)}
              style={getOptionButtonStyles(storeConfig)}
            >
              SHOW OTHER OPTIONS
            </Button>
          )}
          <div className="SelectShippingProviderView__address">
            <div className="SelectShippingProviderView__address-header">
              {ADDRESS_LABEL}
              <Button
                type={BUTTON_TYPE.SENARY}
                className="SelectShippingProviderView__address-button"
                onClick={onReturnToAddress}
              >
                <img className="SelectShippingProviderView__address-icon" src={EditIcon} alt="edit" />
              </Button>
            </div>
            <div className="SelectShippingProviderView__address-info">
              {(firstName || lastName) && (
                <div className="SelectShippingProviderView__address-name">
                  {`${firstName || ''} ${lastName || ''}`}
                </div>
              )}
              <div>
                {`${address1}, ${address2}`}
              </div>
              <div>
                {`${city}, ${provinceCode} ${zip}`}
              </div>
            </div>
          </div>
          <Button
            type={BUTTON_TYPE.QUATERNARY}
            size={BUTTON_SIZE.LARGE}
            onClick={() => onSubmitShippingMethod()}
            style={getNextButtonStyles(storeConfig)}
            disabled={!shippingProviders[selectedShippingMethodIndex]}
            trackerProps={{
              ...stepTrackerProps,
              [POSTHOG_PROPERTIES.TBB_SHIPPING_PROVIDER]:
                shippingProviders[selectedShippingMethodIndex]?.name,
            }}
          >
            {storeConfig?.registerTbbFlow?.nextButton?.textContent || BUTTON_LABEL}
          </Button>
        </>
      )}
    </div>
  );
};

export default SelectShippingProviderView;
