import Dialog from '../../../../../global/Mui/Dialog';
import Check from '../../../../../images/check-green-white.svg';
import Send from '../../../../../images/icon-send.svg';
import TCicon from '../../../../../images/tc-icon-black-stroke.svg';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../../global/Button';
import { getNextButtonStyles } from '../../../../../utils/tbbRegistration';
import './TbbRegisteredDialog.scss';

export default function TbbRegisteredDialog({
  isOpen,
  onClose,
  recyclingProductType,
  storeConfig,
  creditAmt,
  promoCreditAmount,
}) {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <div className="TbbRegisteredDialog">
        <div>
          <img src={Check} alt="check" />
        </div>
        <div className="TbbRegisteredDialog__content">
          <div className="TbbRegisteredDialog__content--title">
            {`${recyclingProductType} REGISTERED AND
                READY FOR DROP OFF!`}
          </div>
          <div className="TbbRegisteredDialog__content--mesages">
            <div className="TbbRegisteredDialog__content--mesages--message">
              <img src={Send} alt="send" />
              {' '}
              Label sent via email
            </div>
            {!storeConfig.registerTbbFlow?.rewardStep?.hideRewards && (
              <div className="TbbRegisteredDialog__content--mesages--message">
                <img src={TCicon} alt="trashie-cash" />
                {' '}
                {Number(creditAmt) + Number(promoCreditAmount)}
                {' '}
                TrashieCash™ added to your balance
              </div>
            )}
          </div>
          <Button
            type={BUTTON_TYPE.QUATERNARY}
            size={BUTTON_SIZE.LARGE}
            onClick={onClose}
            style={getNextButtonStyles(storeConfig)}
          >
            CONTINUE
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
