import classNames from 'classnames';

import './IconCircled.scss';

export const ICON_CIRCLE_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
};

const IconCircled = ({
  className,
  size = ICON_CIRCLE_SIZE.SMALL,
  icon,
  backgroundColor,
  isWhiteLogo,
}) => (
  <div
    className={classNames('IconCircled', size, className, { 'white-logo': isWhiteLogo })}
    style={{ backgroundColor }}
  >
    {icon}
  </div>
);

export default IconCircled;
