import React from 'react';

import IconCircled from '../../../../../global/IconCircled';
import SweepstakesLogo from '../../../../../images/eyes-sweepstakes.svg';

import { ICON_CIRCLE_SIZE } from '../../../../../global/IconCircled/IconCircled';

import './SweeptakesPanel.scss';

export default function SweeptakesPanel({
  title,
  description,
}) {
  return (
    <div className="SweeptakesPanel">
      <span className="SweeptakesPanel__title">
        {title}
      </span>
      <span className="SweeptakesPanel__description">{description}</span>
      <div
        className="SweeptakesPanel__icon-container"
      >
        <IconCircled
          icon={(
            <img
              src={SweepstakesLogo}
              alt="Sweepstakes"
            />
          )}
          size={ICON_CIRCLE_SIZE.MEDIUM}
        />
      </div>
    </div>
  );
}
