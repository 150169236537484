import { useState, useEffect, useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Tab, Tabs } from 'react-bootstrap';
import classNames from 'classnames';
import 'firebase/auth';
import firebase from 'firebase/app';
import _ from 'lodash';

import { CONTACT_US_URL } from '../../config/externalURL';
import crud from '../../../../api/crud';
import { useAlert } from '../../../providers/AlertProvider';
import { REDEMPTION_CODE_STATUS_IDS, TABS, getStepButtonTrackerProps, isTechRecyclingProductType, getHeaderLogo } from '../../utils/tbbRegistration';
import sendRedemptionEmail from '../../../../utils/sendRedemptionEmail';
import { FORDAYS_STORE_ID } from '../../config/config';
import {
  REGISTER_CODE_PARAM,
  REGISTER_PIN_PARAM,
  ROUTE_TAKE_BACK_BAGS_DETAILS,
  REGISTER_FROM_CHECKOUT_PARAM,
} from '../../config/routes';
import { useFirebaseAuth } from '../../../global/FirebaseProvider/FirebaseProvider';
import { emailFormat } from '../../utils/emailFormatValidator';
import TbbRewardPage from '../../global/RegisterTbb/RewardCouponScreen/TbbRewardPage';
import useTbbAmountsExchange from '../../../../hooks/useTbbAmountsExchange';
import { qrIsExpired, isExpiredLabel, shippingProviderStatus } from '../../utils/easyPost';
import { getPaymentStatus, getStore, postRedemptionCode, postShippingLabelRegenerate } from '../../utils/service';
import LoadingBar from '../../global/LoadingBar';
import MessageContent from '../../global/MessageContent';
import StepProgressBar from '../../global/StepProgessBar';
import IconCloudy from '../../images/icon-cloudy.svg';
import WelcomePayOnShip from './Steps/WelcomePayOnShip';
import {
  Welcome,
  Invalid,
  Pin,
  Login,
  TbbForm,
  QrView,
  SelectAddressView,
  EnterNewAddressView,
  SelectShippingProviderView,
  ShippingLabelGenerationError,
  PostRegistration,
  ShippingAndReward,
  ShippingDetails,
  EmailForm,
  RecyclingList,
  DeviceBatteryWarning,
  StickersAdvice,
  QrCodeForm,
  Service,
  Payment,
} from './Steps';

import './Register.scss';
import { errorCodes, getGenericError } from '../../utils/errors';
import { getSearchParam } from '../../utils/routes';

const CODE_LENGTH = 13;
const ERROR_PATTERN = /[\r\n\s]+/g;

const STEPS = [
  [
    TABS.WELCOME,
    TABS.INVALID,
    TABS.MESSAGE,
  ],
  [
    TABS.QR_CODE_FORM,
    TABS.PIN,
    TABS.LOGIN,
    TABS.EMAIL_FORM,
    TABS.SERVICE,
    TABS.PAYMENT,
  ],
  [
    TABS.RECYCLING_LIST,
    TABS.DEVICE_BATTERY_WARNING,
    TABS.STICKERS_ADVICE,
  ],
  [
    TABS.TBB,
    TABS.SELECT_ADDRESS,
    TABS.ENTER_NEW_ADDRESS,
  ],
  [
    TABS.SELECT_SHIPPING_METHOD,
    TABS.SHIPPING_METHOD_GENERATION_ERROR,
    TABS.SHIPPING_REWARD,
    TABS.SHIPPING_DETAILS,
    TABS.COUPON,
    TABS.QR,
  ],
];

const TABS_PROPS = {
  title: false,
  mountOnEnter: true,
  unmountOnExit: true,
};

const MESSAGE_DEFAULT = {
  image: undefined,
  title: undefined,
  cta: {
    text: undefined,
    onClick: undefined,
  },
};

const RedemptionLandingPage = () => {
  const { user, userData, isSignedIn, isLoading: isLoadingFirebase } = useFirebaseAuth();
  const history = useHistory();
  const { search, pathname } = useLocation();
  const setAlert = useAlert();
  const { tbbAmount } = useTbbAmountsExchange();

  const [redemptionCode, setRedemptionCode] = useState(null);
  const [attempts, setAttempts] = useState(5);
  const [isValidPin, setIsValidPin] = useState(true);
  const [isValidCode, setIsValidCode] = useState(null);
  const [emailInput, setEmailInput] = useState('');
  const [requirePin, setRequirePin] = useState(true);
  const [recyclingProductType, setRecyclingProductType] = useState(null);
  const [codeFound, setCodeFound] = useState(true);
  const [codeFoundMessage, setCodeFoundMessage] = useState(null);
  const [pinValues, setPinValues] = useState(Array(6).fill(''));
  const [validPinValues, setValidPinValues] = useState(false);
  const [step, setStep] = useState(0);
  const [tab, setTab] = useState(STEPS[0][0]);
  const [qrCodeImgUrl, setQrCodeImgUrl] = useState('');
  const [printLabelUrl, setPrintLabelUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [qrFailed, setQRFailed] = useState(false);
  const [isTbbFormBtnDisabled, setIsTbbFormBtnDisabled] = useState(true);
  const [couponData, setCouponData] = useState({});
  const [copied, setCopied] = useState(false);
  const [fromAddress, setFromAddress] = useState({});
  const [shippingMethod, setShippingMethod] = useState({});
  const [storeConfig, setStoreConfig] = useState({});
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [creditAmt, setCreditAmt] = useState(0);
  const [isNewUser, setIsNewUser] = useState(false);
  const [isAQrExpired, setIsAQrExpired] = useState(false);
  const [expirationLabelDate, setExpirationLabelDate] = useState(null);
  const [messageContent, setMessageContent] = useState(MESSAGE_DEFAULT);
  const [selectedShippingMethodIndex, setSelectedShippingMethodIndex] = useState(0);
  const [promoCreditAmount, setPromoCreditAmount] = useState(0);
  const [showRewardDialog, setShowRewardDialog] = useState(true);
  const [payOnShipment, setPayOnShipment] = useState({
    isPayOnShip: false,
    payOnShipFee: 0,
    isPaid: false,
  });

  const query = new URLSearchParams(search);

  const isTechRecycling = isTechRecyclingProductType(recyclingProductType);

  const handleTabChange = useCallback((newTab) => {
    setTab(newTab);
    setStep(STEPS.findIndex(stepTabs => stepTabs.includes(newTab)) ?? 0);
  }, []);

  const handleContactSupport = useCallback(() => {
    window.open(CONTACT_US_URL, '_blank');
  }, []);

  const handleGenericError = useCallback((title) => {
    setMessageContent({
      image: IconCloudy,
      title: title ?? 'WE’VE ENCOUNTERED DIFFICULTIES WHILE REGISTERING THE BAG',
      cta: {
        text: 'CONTACT SUPPORT',
        onClick: handleContactSupport,
      },
    });
    handleTabChange(TABS.MESSAGE);
  }, []);

  const validatePaymentStatus = useCallback(async (fromCheckout = false) => {
    const {
      data,
      error: getPaymentStatusError,
    } = await getPaymentStatus(redemptionCode, fromCheckout);

    if (getPaymentStatusError || !data.isPaid) {
      handleGenericError(errorCodes[getPaymentStatusError?.code]);
      return;
    }

    handleTabChange(TABS.PIN);
  }, [redemptionCode]);

  const handlePayOnShipment = useCallback(async ({
    isPayOnShip,
    isPaid,
  }) => {
    if (!isPayOnShip) {
      return;
    }

    if (tab === TABS.LOGIN) {
      if (!isSignedIn) {
        setIsLoading(false);
        return;
      }

      if (isPaid) {
        await validatePaymentStatus();
      } else {
        handleTabChange(TABS.PAYMENT);
      }
    }

    if (tab === TABS.PIN) {
      handleTabChange(TABS.SELECT_ADDRESS);
    }

    if (tab === TABS.SERVICE) {
      handleTabChange(isSignedIn ? TABS.PAYMENT : TABS.LOGIN);
    }

    if (tab === TABS.QR_CODE_FORM) {
      handleTabChange(TABS.SERVICE);
    }

    const fromCheckout = getSearchParam(query, REGISTER_FROM_CHECKOUT_PARAM) === 'true';

    if (tab === TABS.WELCOME && isSignedIn && (isPaid || fromCheckout)) {
      await validatePaymentStatus(fromCheckout);
    }

    setIsLoading(false);
  }, [
    isSignedIn,
    user,
    tab,
    redemptionCode,
    query,
    validatePaymentStatus,
    handleTabChange,
  ]);

  const getAndSetStore = useCallback(async (storeId) => {
    const {
      data: whiteLabelStore,
      error: getStoreError,
    } = await getStore(storeId);

    if (getStoreError) {
      setAlert({
        type: 'notification',
        message: getStoreError.message || getGenericError(),
      });
      return;
    }

    const whiteLabelStoreObj = {
      ...whiteLabelStore.meta,
      storeType: whiteLabelStore.storeType,
      id: whiteLabelStore.id,
      uid: whiteLabelStore.uid,
      couponRule: whiteLabelStore.couponRule,
    };

    document.body.style.backgroundColor = whiteLabelStoreObj.color2;

    if (!whiteLabelStoreObj.registerTbbFlow) {
      setIsValidCode(false);
    }

    setStoreConfig(whiteLabelStoreObj);
    setCreditAmt(whiteLabelStoreObj?.registerTbbFlow?.rewardStep?.cashValue);
  }, []);

  const getExistingRedemption = useCallback(async () => {
    let fetchedExistingRedemption = await crud.get({
      path: `/redemptions/${redemptionCode}`,
    });
    const isQrExpired = qrIsExpired(
      fetchedExistingRedemption.redeemedAt,
      fetchedExistingRedemption.shipment.shipmentStatus,
      fetchedExistingRedemption.shipment.shippingLabelExpiresAt,
    );
    setIsAQrExpired(isQrExpired);

    if (isExpiredLabel(fetchedExistingRedemption.shipment.shippingLabelExpiresAt) &&
      (fetchedExistingRedemption.shipment.shipmentStatus === shippingProviderStatus.unknown ||
        fetchedExistingRedemption.shipment.shipmentStatus === shippingProviderStatus.pre_transit)
    ) {
      try {
        const { data } = await postShippingLabelRegenerate(redemptionCode);
        if (!_.isEmpty(data)) {
          fetchedExistingRedemption = { ...fetchedExistingRedemption, ...data };
        }
      } catch (error) {
        setAlert({
          type: 'notification',
          message: `Code 2: ${error}`,
        });
      }
    }

    setCreditAmt(fetchedExistingRedemption.creditAmt);

    if (
      fetchedExistingRedemption?.labeldata
      && !JSON.parse(fetchedExistingRedemption.labeldata).error
    ) {
      const labelData = JSON.parse(fetchedExistingRedemption.labeldata);
      const qrUrl = labelData?.forms?.find(({ form_type: formType }) => formType === 'label_qr_code')?.form_url || fetchedExistingRedemption?.shipment?.qrLabelUrl;
      const printLabelUrlTmp = labelData?.postage_label?.label_pdf_url ?
        labelData.postage_label.label_pdf_url :
        labelData?.postage_label?.label_url;
      setExpirationLabelDate(fetchedExistingRedemption?.shipment.shippingLabelExpiresAt);
      setCouponData({
        couponCode: fetchedExistingRedemption?.couponData,
        summary: redemptionCode.includes('-') ? redemptionCode.split('-')[1] : '',
      });
      setQrCodeImgUrl(qrUrl);
      setPrintLabelUrl(printLabelUrlTmp);
      setShippingMethod(fetchedExistingRedemption?.shipment?.shippingMethod);
      setEmailInput(fetchedExistingRedemption?.redeemedByEmail ?? '');
      setIsNewUser(fetchedExistingRedemption?.redeemedByNew ?? false);
      const address = labelData?.from_address;
      if (address) {
        // province_code and zip_code are present for happy returns
        // state and zip are present for ups shipping label
        const provinceCode = address.state || address.province_code;
        const zip = address.zip || address.zip_code;
        setFromAddress({ ...address, provinceCode, zip });
      }
      handleTabChange(TABS.SHIPPING_REWARD);
    } else if (
      fetchedExistingRedemption?.labeldata
      && JSON.parse(fetchedExistingRedemption.labeldata).error
    ) {
      crud.post({
        path: `/redemptions/revertcode/${fetchedExistingRedemption.uid}`,
      });
    }
  }, [redemptionCode]);

  const handlePinStep = useCallback(() => {
    if (payOnShipment.isPayOnShip) {
      handlePayOnShipment(payOnShipment);
      return;
    }

    if (storeConfig?.registerTbbFlow?.isAuthFreeFlow) {
      handleTabChange(TABS.EMAIL_FORM);
      return;
    }
    if (isSignedIn && user) {
      handleTabChange(isTechRecycling ? TABS.RECYCLING_LIST : TABS.SELECT_ADDRESS);
      return;
    }

    handleTabChange(TABS.LOGIN);
  }, [
    isSignedIn,
    user,
    handleTabChange,
    storeConfig,
    isTechRecycling,
    payOnShipment.isPayOnShip,
    handlePayOnShipment,
  ]);

  const handleServiceStep = useCallback(() => {
    handlePayOnShipment(payOnShipment);
  }, [handlePayOnShipment, payOnShipment]);

  const returnToAddressStep = useCallback(() => {
    handleTabChange(TABS.SELECT_ADDRESS);
  }, [handleTabChange]);

  /**
   * This method is necessary because some QR codes have a printing error that needs to be removed.
   * It cleans the provided code by removing any unwanted characters
   * and truncating it to the specified length.
   * The cleaned code is then set as a query parameter in the URL.
   *
   * @param {string} code - The code to be cleaned.
   * @param {number} length - The desired length of the cleaned code. Default is CODE_LENGTH.
   * @returns {string} - The cleaned code.
   */
  const cleanCode = useCallback(
    (code, length = CODE_LENGTH) => {
      if (!code) return '';
      const cleanedCode = code.replace(ERROR_PATTERN, '').slice(0, length);
      query.set(REGISTER_CODE_PARAM, cleanedCode);
      const newUrl = `${pathname}?${query.toString()}`;
      history.replace(newUrl);

      return cleanedCode;
    },
    [query, pathname, history],
  );

  const validateCode = useCallback(async (manualQrCode = null) => {
    setIsLoading(true);
    setIsValidCode(false);

    if (manualQrCode) {
      setRedemptionCode(manualQrCode);
      query.set(REGISTER_CODE_PARAM, manualQrCode);
      const newUrl = `${pathname}?${query.toString()}`;
      history.replace(newUrl);
    }

    if (!redemptionCode && !manualQrCode) {
      setIsLoading(false);
      return;
    }

    try {
      const redemptionResult = await crud.get({
        path: `/redemptions/validate/${manualQrCode || redemptionCode}`,
        cacheControl: 'no-cache',
      });

      if (!redemptionResult?.found) {
        setCodeFound(false);
        setCodeFoundMessage(redemptionResult?.message ?? 'An error has occurred when trying to redeem your code, please contact the store');
        setIsLoading(false);
        return;
      }

      setCodeFound(true);
      setIsValidCode(true);

      if (redemptionResult?.statusId === REDEMPTION_CODE_STATUS_IDS.CLAIMED) {
        if (!redemptionResult?.isAuthFreeFlow) {
          history.push(`${ROUTE_TAKE_BACK_BAGS_DETAILS}?${REGISTER_CODE_PARAM}=${manualQrCode || redemptionCode}`);
          return;
        }
        await getExistingRedemption();
        setShowRewardDialog(false);
        handleTabChange(TABS.SHIPPING_REWARD);
      }

      const redemptionRequirePin = !!redemptionResult?.requirePIN;
      setRequirePin(redemptionRequirePin);
      if (redemptionRequirePin) {
        setAttempts(redemptionResult?.attempts ?? 5);
      }

      await getAndSetStore(redemptionResult.storeId);
      setRecyclingProductType(redemptionResult?.recyclingProductType);

      setPayOnShipment({
        isPayOnShip: redemptionResult?.isPayOnShip,
        payOnShipFee: redemptionResult?.payOnShipFee,
        isPaid: redemptionResult?.isPaid,
      });

      if (manualQrCode) {
        if (!redemptionResult?.found) {
          handleTabChange(TABS.INVALID);
        }

        if (redemptionResult?.isPayOnShip) {
          handlePayOnShipment(redemptionResult);
          return;
        }

        if (redemptionRequirePin) {
          handleTabChange(TABS.PIN);
        } else {
          handlePinStep();
        }
      } else if (redemptionResult?.isPayOnShip) {
        handlePayOnShipment(redemptionResult);
        return;
      }

      setIsLoading(false);
    } catch (error) {
      if (error.error) {
        // A variable is assigned the value of found found in the API response
        const redmCodeFound = error?.found ?? false;
        const msgError = error?.message ?? '';
        setCodeFoundMessage('An error has occurred when trying to redeem your code, please contact the support.');
        setIsValidCode(false);
        if (manualQrCode) {
          handleTabChange(TABS.INVALID);
        }
        // If the code does not exist, it will enter the first condition and display the not found
        // message, if found is true it will display the normal view unless it has no more attempts.
        if (!redmCodeFound) {
          setCodeFoundMessage('The bag code you are trying to redeem was not found in our records, if you think this is an error please contact support.');
        } else if (redmCodeFound && msgError.includes('FD-R-011')) {
          setCodeFoundMessage('You have tried to redeem the code more than 5 times with a wrong PIN, please contact support.');
        } else {
          setAlert({
            type: 'notification',
            message: `Code 2: ${msgError}`,
          });
        }
      }

      setIsLoading(false);
    }
  }, [redemptionCode, codeFound, query, pathname, history, handlePayOnShipment]);

  const stepTrackerProps = useMemo(() => (
    getStepButtonTrackerProps(
      tab,
      recyclingProductType,
      payOnShipment.isPayOnShip,
    )
  ), [tab, recyclingProductType, payOnShipment.isPayOnShip]);

  const handleSetPinValues = useCallback((values) => {
    setPinValues(values);
    setValidPinValues(values.filter(value => value !== '').length === 6);
  }, []);

  const handleOnSelectingAddress = useCallback((address) => {
    setFromAddress(address);
    handleTabChange(TABS.SELECT_SHIPPING_METHOD);
  }, []);

  const handleBackToSelectAddress = useCallback(() => {
    setFromAddress({});
    handleTabChange(TABS.SELECT_ADDRESS);
  }, []);

  const handleNewAddress = useCallback((resetAddress = true) => {
    if (resetAddress) setFromAddress({});
    handleTabChange(TABS.ENTER_NEW_ADDRESS);
  }, []);

  const handleOnSelectShippingMethod = useCallback(async () => {
    setIsLoading(true);
    setPromoCreditAmount(shippingMethod?.promotion?.amount ?? 0);

    const { error } = await postRedemptionCode({
      id: redemptionCode,
      pin: pinValues.join(''),
      email: _.isEmpty(emailInput) ? userData?.email : emailInput,
      redemptionStatus: 'claimed',
      creditAmt: storeConfig.uid === 1 ? tbbAmount : 20,
      storeId: storeConfig.id || FORDAYS_STORE_ID,
      storeUid: storeConfig.uid || 1,
      fromAddress,
      shippingProviderId: shippingMethod?.uid,
    });
    await getExistingRedemption();
    await sendRedemptionEmail(redemptionCode, user, emailInput);
    setIsLoading(false);
    if (error) {
      handleTabChange(TABS.SHIPPING_METHOD_GENERATION_ERROR);
    } else {
      handleTabChange(TABS.SHIPPING_REWARD);
    }
  }, [redemptionCode, pinValues, storeConfig, fromAddress, shippingMethod]);

  const handleSubmit = async (e, addressObject) => {
    e.preventDefault();
    if (emailFormat(emailInput)) {
      setIsValidEmail(true);
    } else {
      setAlert({
        type: 'passive',
        message: 'Improperly formatted email. Please try again.',
      });
      setIsValidEmail(false);
      return;
    }
    try {
      setIsLoading(true);

      let redemptionRes;
      try {
        redemptionRes = await crud.post({
          path: '/redemptions',
          body: {
            data: {
              id: redemptionCode,
              pin: pinValues.join(''),
              email: emailInput,
              redemptionStatus: 'claimed',
              creditAmt: storeConfig.uid === 1 ? tbbAmount : 20,
              storeId: storeConfig.id || FORDAYS_STORE_ID,
              storeUid: storeConfig.uid || 1,
              fromAddress: addressObject,
            },
          },
        });

        redemptionRes = (typeof redemptionRes === 'object') ? redemptionRes : JSON.parse(redemptionRes);
        if (redemptionRes?.registerResult) {
          setCreditAmt(redemptionRes.registerResult.creditAmt);
          setEmailInput(redemptionRes.registerResult?.redeemedByEmail ?? '');
          setIsNewUser(redemptionRes.registerResult?.redeemedByNew ?? false);
          setExpirationLabelDate(redemptionRes.registerResult?.shippingLabelExpiresAt);
        }
        if (redemptionRes.invalid) {
          setIsLoading(false);
          setIsValidPin(false);
          setIsValidCode(true);
          setAttempts(redemptionRes.attempts);
          if (redemptionRes.attempts === 0) {
            validateCode();
          }

          return;
        }
      } catch (error) {
        setIsLoading(false);
        setQRFailed(true);
        return;
      }

      if (redemptionRes === 'noFromAddress') {
        setStep(4);
        return;
      }

      handleTabChange(TABS.SHIPPING_DETAILS);

      let qrFormObj;
      try {
        qrFormObj = redemptionRes.forms?.find(
          ({ form_type: formType }) => formType === 'label_qr_code',
        ) || redemptionRes?.shipment?.qrLabelUrl;
      } catch (error) {
        setIsLoading(false);
        setQRFailed(true);
        setAlert({
          type: 'alert',
          message: `Code 2: ${error.message}`,
        });
      }

      const printLabelUrlTmp = redemptionRes?.postage_label?.label_pdf_url ?
        redemptionRes?.postage_label?.label_pdf_url :
        redemptionRes?.postage_label?.label_url;

      setCouponData(redemptionRes?.couponData);

      if (qrFormObj) {
        setQrCodeImgUrl(qrFormObj.form_url);
        setPrintLabelUrl(printLabelUrlTmp);
        try {
          await sendRedemptionEmail(redemptionCode, user, emailInput);
        } catch (error) {
          // do nothing
        }
      } else {
        setQRFailed(true);
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      setAlert({
        type: 'alert',
        message: `Code 3: ${error.message}`,
      });
      setIsLoading(false);
    }
  };

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(couponData?.couponCode);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, '1850');
  }, [couponData]);

  const handleLoginStep = useCallback(async ({
    [REGISTER_PIN_PARAM]: pin,
  }) => {
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      if (payOnShipment.isPayOnShip) {
        handlePayOnShipment(payOnShipment);
        return;
      }

      if (requirePin) {
        if (!pin) {
          handleGenericError();
          setIsLoading(false);
          return;
        }

        const response = await crud.post({
          path: '/redemptions/validatePinCode',
          body: {
            data: {
              id: redemptionCode,
              pin,
            },
          },
        });

        if (response.invalid) {
          handleSetPinValues([]);
          handleGenericError();
          setIsLoading(false);
          return;
        }

        handleSetPinValues(pin.split(''));
        handleTabChange(isTechRecycling ? TABS.RECYCLING_LIST : TABS.SELECT_ADDRESS);

        setIsLoading(false);
        return;
      }
    }

    if (payOnShipment.isPayOnShip) {
      handlePayOnShipment(payOnShipment);
      return;
    }

    if (requirePin && pinValues.join('') === '') {
      handleGenericError();
      setIsLoading(false);
      return;
    }

    handleTabChange(isTechRecycling ? TABS.RECYCLING_LIST : TABS.SELECT_ADDRESS);

    setIsLoading(false);
  }, [
    handleTabChange,
    handleSetPinValues,
    handlePayOnShipment,
    pinValues,
    redemptionCode,
    requirePin,
    isTechRecycling,
    payOnShipment.isPayOnShip,
    payOnShipment.isPaid,
  ]);

  const handleWelcomeStep = useCallback(() => {
    if (!isValidCode) {
      handleTabChange(TABS.QR_CODE_FORM);
      return;
    }

    if (payOnShipment.isPayOnShip) {
      handleTabChange(TABS.SERVICE);
      return;
    }

    if (requirePin) {
      handleTabChange(TABS.PIN);
    } else {
      handlePinStep();
    }
  }, [isValidCode, requirePin, payOnShipment.isPayOnShip, handlePinStep]);

  const handleQrCodeFormStep = useCallback(async (code) => {
    await validateCode(code);
  }, [validateCode]);

  const handleInvalidStep = useCallback(() => {
    handleTabChange(TABS.QR_CODE_FORM);
  }, []);

  const handleEmailFormStep = useCallback(() => {
    handleTabChange(TABS.ENTER_NEW_ADDRESS);
  }, []);

  const handleRecyclingListStep = useCallback(() => {
    handleTabChange(TABS.DEVICE_BATTERY_WARNING);
  }, []);

  const handleOnBatteryWarningSubmit = useCallback(() => {
    handleTabChange(TABS.STICKERS_ADVICE);
  }, []);

  const handleOnStickersAdviceSubmit = useCallback(() => {
    handleTabChange(TABS.SELECT_ADDRESS);
  }, []);

  const handleOnQrFormSubmit = useCallback(() => {
    handleTabChange(TABS.COUPON);
  }, []);

  const showHeader = useMemo(() => step !== 0 && tab !== TABS.LOGIN, [step, tab]);

  useEffect(() => {
    if (redemptionCode === null) {
      let newRedemptionCode = null;
      const urlCode = query.get(REGISTER_CODE_PARAM);

      if (urlCode) {
        const cleanedCode = ERROR_PATTERN.test(urlCode) ? cleanCode(urlCode) : urlCode;
        const splitCode = cleanedCode.split('-')[0] ?? '';

        if (splitCode.length === CODE_LENGTH && Number(splitCode)) {
          newRedemptionCode = cleanedCode;
        } else if (cleanedCode.length === CODE_LENGTH && Number(cleanedCode)) {
          newRedemptionCode = cleanedCode;
        } else if (Number.isNaN(cleanedCode)) {
          try {
            newRedemptionCode = window.atob(cleanedCode);
          } catch (e) {
            newRedemptionCode = null;
          }
        }
      }

      setRedemptionCode(newRedemptionCode);
      if (!newRedemptionCode) {
        setIsLoading(false);
      }
    }

    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      handleTabChange(TABS.LOGIN);
    }
  }, []);

  useEffect(() => {
    if (redemptionCode && isValidCode === null && !isLoadingFirebase) {
      validateCode();
    }
  }, [redemptionCode, isLoadingFirebase]);

  useEffect(() => {
    setIsTbbFormBtnDisabled(!emailInput || (requirePin && !validPinValues));
  }, [emailInput, validPinValues]);

  if (isLoading) {
    return (
      <div className="RegisterView">
        <div className="RegisterView__contentContainer">
          <LoadingBar className="loader" />
        </div>
      </div>
    );
  }

  return (
    <div className="RegisterView">
      <div
        className={classNames('RegisterView__container', {
          rainbowBg: (storeConfig.isTrashie || !isValidCode)
            && TABS.WELCOME === tab && !payOnShipment.isPayOnShip,
        })}
        style={{ background: step === 0 ? storeConfig?.registerTbbFlow?.landingPageStep?.bgColor : '' }}
      >
        {showHeader && (
          <div className="RegisterView__header">
            <div className="RegisterView__header--content">
              <img
                src={getHeaderLogo(storeConfig)}
                alt="recycle bag"
                style={storeConfig?.registerTbbFlow?.headerLogoProperties}
              />
              <span className="RegisterView__header--content-text">
                {redemptionCode && `${isTechRecycling ? 'BOX ID' : 'BAG ID'}: *******${redemptionCode.slice(-6)}`}
              </span>
            </div>
          </div>
        )}
        {
          showHeader &&
          tab !== TABS.SHIPPING_REWARD &&
          tab !== TABS.SHIPPING_DETAILS && step !== 4 && (
            <div className="RegisterView__progressContainer">
              <StepProgressBar
                currentStep={step}
                totalSteps={isTechRecycling ?
                  STEPS.length : STEPS.length - 1}
                color={!storeConfig?.isTrashie ? storeConfig?.registerTbbFlow?.nextButton?.bgColor : ''}
              />
            </div>
          )
        }
        <div className="RegisterView__contentContainer">
          <Tabs
            activeKey={tab}
            transition={false}
            mountOnEnter
            unmountOnExit
          >
            <Tab
              eventKey={TABS.WELCOME}
              {...TABS_PROPS}
            >
              {payOnShipment.isPayOnShip ? (
                <WelcomePayOnShip
                  handleOnClick={handleWelcomeStep}
                  storeConfig={storeConfig}
                  stepTrackerProps={stepTrackerProps}
                  isValidCode={isValidCode}
                />
              ) : (
                <Welcome
                  handleOnClick={handleWelcomeStep}
                  recyclingProductType={recyclingProductType}
                  storeConfig={storeConfig}
                  stepTrackerProps={stepTrackerProps}
                  isValidCode={isValidCode}
                />
              )}
            </Tab>
            <Tab
              eventKey={TABS.QR_CODE_FORM}
              {...TABS_PROPS}
            >
              <QrCodeForm
                onCancel={handleContactSupport}
                onClick={handleQrCodeFormStep}
              />
            </Tab>
            <Tab
              eventKey={TABS.INVALID}
              {...TABS_PROPS}
            >
              <Invalid
                onClick={handleInvalidStep}
                onCancel={handleContactSupport}
                code={redemptionCode}
                storeConfig={storeConfig}
                recyclingProductType={recyclingProductType}
              />
            </Tab>
            <Tab
              eventKey={TABS.SERVICE}
              {...TABS_PROPS}
            >
              <Service
                code={redemptionCode}
                creditAmt={creditAmt}
                payOnShipFee={payOnShipment.payOnShipFee}
                storeConfig={storeConfig}
                onSubmit={handleServiceStep}
                stepTrackerProps={stepTrackerProps}
              />
            </Tab>
            <Tab
              eventKey={TABS.PAYMENT}
              {...TABS_PROPS}
            >
              <Payment
                code={redemptionCode}
                creditAmt={creditAmt}
                payOnShipFee={payOnShipment.payOnShipFee}
                storeConfig={storeConfig}
              />
            </Tab>
            <Tab
              eventKey={TABS.PIN}
              {...TABS_PROPS}
            >
              <Pin
                storeConfig={storeConfig}
                onSubmit={handlePinStep}
                onCancel={handleContactSupport}
                registerCode={redemptionCode}
                pinValues={pinValues}
                setPinValues={handleSetPinValues}
                recyclingProductType={recyclingProductType}
                stepTrackerProps={stepTrackerProps}
              />
            </Tab>
            <Tab
              eventKey={TABS.LOGIN}
              {...TABS_PROPS}
            >
              <Login
                storeConfig={storeConfig}
                emailLinkParams={{ [REGISTER_PIN_PARAM]: pinValues.join('') }}
                onSubmit={handleLoginStep}
                onError={handleGenericError}
              />
            </Tab>
            <Tab
              eventKey={TABS.RECYCLING_LIST}
              {...TABS_PROPS}
            >
              <RecyclingList
                stepTrackerProps={stepTrackerProps}
                onSubmit={handleRecyclingListStep}
              />
            </Tab>
            <Tab
              eventKey={TABS.DEVICE_BATTERY_WARNING}
              {...TABS_PROPS}
            >
              <DeviceBatteryWarning
                stepTrackerProps={stepTrackerProps}
                onCancel={handleContactSupport}
                onSubmit={handleOnBatteryWarningSubmit}
              />
            </Tab>
            <Tab
              eventKey={TABS.STICKERS_ADVICE}
              {...TABS_PROPS}
            >
              <StickersAdvice
                onSubmit={handleOnStickersAdviceSubmit}
                stepTrackerProps={stepTrackerProps}
              />
            </Tab>
            <Tab
              eventKey={TABS.EMAIL_FORM}
              {...TABS_PROPS}
            >
              <EmailForm
                storeConfig={storeConfig}
                email={emailInput}
                recyclingProductType={recyclingProductType}
                onEmailChange={setEmailInput}
                onSubmit={handleEmailFormStep}
                stepTrackerProps={stepTrackerProps}
              />
            </Tab>
            <Tab
              eventKey={TABS.SELECT_ADDRESS}
              {...TABS_PROPS}
            >
              <SelectAddressView
                addressSelected={fromAddress}
                stepTrackerProps={stepTrackerProps}
                onSubmit={handleOnSelectingAddress}
                onEmptyAddresses={() => handleTabChange(TABS.ENTER_NEW_ADDRESS)}
                onNewAddress={handleNewAddress}
                storeConfig={storeConfig}
              />
            </Tab>
            <Tab
              eventKey={TABS.ENTER_NEW_ADDRESS}
              {...TABS_PROPS}
            >
              <EnterNewAddressView
                addressSelected={fromAddress}
                userData={userData}
                onBackToSelectAddress={handleBackToSelectAddress}
                setFromAddress={handleOnSelectingAddress}
                storeConfig={storeConfig}
                recyclingProductType={recyclingProductType}
                stepTrackerProps={stepTrackerProps}
              />
            </Tab>
            <Tab
              eventKey={TABS.SELECT_SHIPPING_METHOD}
              {...TABS_PROPS}
            >
              <SelectShippingProviderView
                setShippingMethod={setShippingMethod}
                onSubmitShippingMethod={handleOnSelectShippingMethod}
                onReturnToAddress={returnToAddressStep}
                storeConfig={storeConfig}
                selectedShippingMethodIndex={selectedShippingMethodIndex}
                setSelectedShippingMethodIndex={setSelectedShippingMethodIndex}
                address={fromAddress}
                onError={handleGenericError}
                recyclingProductType={recyclingProductType}
                stepTrackerProps={stepTrackerProps}
              />
            </Tab>
            <Tab
              eventKey={TABS.SHIPPING_METHOD_GENERATION_ERROR}
              {...TABS_PROPS}
            >
              <ShippingLabelGenerationError
                onRetry={() => handleTabChange(TABS.SELECT_SHIPPING_METHOD)}
                onGetSupport={handleContactSupport}
                storeConfig={storeConfig}
              />
            </Tab>
            <Tab
              eventKey={TABS.TBB} // REMOVE !!!
              {...TABS_PROPS}
            >
              <TbbForm
                isValidPin={isValidPin}
                attempts={attempts}
                codeFound={codeFound}
                codeFoundMessage={codeFoundMessage}
                requirePin={requirePin}
                pinValues={pinValues}
                setPinValues={(values) => {
                  setPinValues(values);
                  setValidPinValues(values.filter(value => value !== '').length === 6);
                }}
                storeConfig={storeConfig}
                redemptionCode={redemptionCode}
                emailInput={emailInput}
                isTbbFormBtnDisabled={isTbbFormBtnDisabled}
                onSubmit={handleSubmit}
                onEmailInputChange={(e) => setEmailInput(e.target.value.toLowerCase())}
                address={fromAddress}
                isValidEmail={isValidEmail}
                onEditAddress={() => {
                  handleTabChange(TABS.ENTER_NEW_ADDRESS);
                }}
              />
            </Tab>
            <Tab
              eventKey={TABS.QR}
              {...TABS_PROPS}
            >
              <QrView
                qrFailed={qrFailed}
                storeConfig={storeConfig}
                qrCodeImgUrl={qrCodeImgUrl}
                printLabelUrl={printLabelUrl}
                onQrFormButtonClick={handleOnQrFormSubmit}
                isAQrExpired={isAQrExpired}
                expirationLabelDate={expirationLabelDate}
                stepTrackerProps={stepTrackerProps}
              />
            </Tab>
            <Tab
              eventKey={TABS.COUPON}
              {...TABS_PROPS}
            >
              <TbbRewardPage
                creditAmt={creditAmt}
                fromAddress={fromAddress}
                storeConfig={storeConfig}
                couponData={couponData}
                copied={copied}
                handleCopy={handleCopy}
                emailInput={emailInput}
                isNewUser={isNewUser}
                stepTrackerProps={stepTrackerProps}
              />
            </Tab>
            <Tab
              eventKey={TABS.MESSAGE}
              {...TABS_PROPS}
            >
              <MessageContent {...messageContent} />
            </Tab>
            <Tab
              eventKey={TABS.SHIPPING_REWARD}
              {...TABS_PROPS}
            >
              {payOnShipment.isPayOnShip ? (
                <PostRegistration
                  storeConfig={storeConfig}
                  onClick={() => handleTabChange(TABS.SHIPPING_DETAILS)}
                  shippingMethod={shippingMethod}
                  address={fromAddress}
                  stepTrackerProps={stepTrackerProps}
                  redemptionCode={redemptionCode}
                  showDialog={showRewardDialog}
                  recyclingProductType={isTechRecycling ? 'BOX' : 'BAG'}
                  creditAmt={creditAmt}
                  promoCreditAmount={promoCreditAmount}
                />
              )
                : (
                  <ShippingAndReward
                    storeConfig={storeConfig}
                    onClick={() => handleTabChange(TABS.SHIPPING_DETAILS)}
                    shippingMethod={shippingMethod}
                    creditAmt={creditAmt}
                    promoCreditAmount={promoCreditAmount}
                    couponData={couponData}
                    address={fromAddress}
                    showDialog={showRewardDialog}
                    recyclingProductType={isTechRecycling ? 'BOX' : 'BAG'}
                    redemptionCode={redemptionCode}
                  />
                )}
            </Tab>
            <Tab
              eventKey={TABS.SHIPPING_DETAILS}
              {...TABS_PROPS}
            >
              <ShippingDetails
                code={redemptionCode}
                shippingMethod={shippingMethod}
                expirationLabelDate={expirationLabelDate}
                qrCodeImgUrl={qrCodeImgUrl}
                printLabelUrl={printLabelUrl}
                address={fromAddress}
                isAuthFreeFlow={storeConfig?.registerTbbFlow?.isAuthFreeFlow}
                recyclingProductType={recyclingProductType}
                storeConfig={storeConfig}
              />
            </Tab>
          </Tabs>
        </div>
        {![TABS.WELCOME, TABS.SHIPPING_DETAILS, TABS.LOGIN, TABS.SERVICE].includes(tab) && (
          <div className="RegisterView__footer">
            <span>Powered by</span>
            <a href="https://www.trashie.io">Trashie</a>
          </div>
        )}
      </div>
    </div>
  );
};

export default RedemptionLandingPage;
