import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';

import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../../../global/Button';
import InfoSection from '../../../../global/InfoSection';
import {
  getNextButtonStyles,
  TBB_CREDIT_AMOUNT_TAG,
  TBB_PARTNER_TAG,
  TBB_PAYMENT_CONFIG_ITEMS,
  TBB_PAYMENT_CONFIG_ITEMS_CONTENT,
  insertTCIcon,
} from '../../../../utils/tbbRegistration';
import { getGiftCardsBrandsOffer, getRewardsExclusive, getRewardsOffer } from '../../../../utils/service';
import { REWARD_STATUSES } from '../../../../config/rewards';
import useTbbPaymentConfig from '../../../../../../hooks/useTbbPaymentConfig';
import { ICON_CIRCLE_SIZE } from '../../../../global/IconCircled';
import CircularRewardsCarousel from '../../../../global/CircularRewardsCarousel';
import LoadingBar from '../../../../global/LoadingBar';
import Slider, { SLIDER_TYPES, CAROUSEL_TYPES } from '../../../../global/Slider';
import DealCard from '../../../../global/DynamicDealsBanner/DealCard';
import { FLAGS, getCategoryParam } from '../../../Rewards/utils';
import GiftCardItem, { GIFT_CARD_ITEM_TYPE } from '../../../GiftCards/GiftCardItem';
import { formatCurrencyNumber } from '../../../../utils/numbers';

import './Service.scss';

const Service = ({
  code,
  creditAmt,
  payOnShipFee,
  storeConfig,
  onSubmit,
  stepTrackerProps,
}) => {
  const { isLoading: isLoadingTbbPaymentConfig, tbbPaymentConfig } = useTbbPaymentConfig();

  const [rewards, setRewards] = useState([]);
  const [rewardsExclusive, setRewardsExclusive] = useState([]);
  const [giftCards, setGiftCards] = useState([]);
  const [loadingInfo, setLoadingInfo] = useState(true);

  const fetchData = useCallback(async () => {
    setLoadingInfo(true);

    const data = await Promise.all([
      getRewardsOffer({
        page: 1,
        pageSize: 8,
        sort: 'sortRank',
        exchangeValueMin: 1,
        status: REWARD_STATUSES.ACTIVE,
      }),
      getRewardsExclusive({
        redemptionCode: code,
        page: 1,
        pageSize: 10,
      }),
      getGiftCardsBrandsOffer({
        pageSize: 6,
        page: 1,
        ...getCategoryParam(FLAGS.IS_HOT),
      }),
    ]);

    const {
      data: rewardsData,
      error: rewardsError,
    } = data[0];

    const {
      data: rewardsExclusiveData,
      error: rewardsExclusiveError,
    } = data[1];

    const {
      data: giftCardsData,
      error: giftCardsError,
    } = data[2];

    setRewards(rewardsError ? [] : rewardsData.rewards);
    setRewardsExclusive(rewardsExclusiveError ? [] : rewardsExclusiveData.rewards);
    setGiftCards(giftCardsError ? [] : giftCardsData.brands);
    setLoadingInfo(false);
  }, []);

  const items = useMemo(
    () => tbbPaymentConfig.items?.map(item => {
      const title = item.titleService?.replace(TBB_PARTNER_TAG, storeConfig?.title);
      const description = item.description
        ?.replace(TBB_PARTNER_TAG, storeConfig?.title)
        .replace(TBB_CREDIT_AMOUNT_TAG, creditAmt);

      switch (item.id) {
        case TBB_PAYMENT_CONFIG_ITEMS.recycling:
          return {
            key: item.id,
            title,
            description,
            content: (
              <div className="RegisterService__container--recycling-content">
                {TBB_PAYMENT_CONFIG_ITEMS_CONTENT[item.id]?.map(({
                  title: contentTitle,
                  description: contentDescription,
                  getIcon: getContentIcon,
                  className,
                }) => (
                  <RegisterServiceContentItem
                    key={contentTitle}
                    title={contentTitle}
                    description={contentDescription}
                    icon={getContentIcon(ICON_CIRCLE_SIZE.MEDIUM)}
                    className={className}
                  />
                ))}
              </div>
            ),
          };
        case TBB_PAYMENT_CONFIG_ITEMS.exclusive_rewards:
          return rewardsExclusive.length > 0 ? {
            key: item.id,
            title,
            description,
            content: <CircularRewardsCarousel rewards={rewardsExclusive} />,
          } : null;
        case TBB_PAYMENT_CONFIG_ITEMS.gift_cards:
          return giftCards.length > 0 ? {
            key: item.id,
            title,
            description,
            content: (
              <div className="RegisterService__container--item-content-slider">
                <Slider
                  type={SLIDER_TYPES.OVERFLOW}
                  autoplay={false}
                  rewind={false}
                  paginationDots={false}
                  gap={8}
                  fixedPerPage
                  trackPadding={false}
                  slidesPerPage={2}
                  slides={giftCards.map(giftCardItem => ({
                    key: `giftCard-preview-${giftCardItem.uid}`,
                    slide: (
                      <GiftCardItem
                        key={`giftCard-slide-${giftCardItem.uid}`}
                        type={GIFT_CARD_ITEM_TYPE.EXTENDED}
                        giftCardItem={giftCardItem}
                      />
                    ),
                  }))}
                />
              </div>
            ),
          } : null;
        case TBB_PAYMENT_CONFIG_ITEMS.unlock_rewards:
          return rewards.length > 0 ? {
            key: item.id,
            title,
            description,
            content: (
              <div className="RegisterService__container--item-content-slider">
                <Slider
                  carouselType={CAROUSEL_TYPES.FADE}
                  pauseOnHover={false}
                  interval={2000}
                  trackPadding={false}
                  slides={rewards.map((reward) => ({
                    key: reward.uid,
                    slide: (
                      <DealCard
                        dealItem={reward}
                        isCircleVariant
                        containerClassName="RegisterService__container--item-content-slider-card"
                      />
                    ),
                  }))}
                />
              </div>
            ),
          } : null;
        default:
          return null;
      }
    }).filter(Boolean),
    [tbbPaymentConfig, storeConfig, rewards, rewardsExclusive, giftCards],
  );

  const isLoadingInfo = isLoadingTbbPaymentConfig || loadingInfo;

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className="RegisterService">
      <InfoSection
        titleText="Take Back Service"
        titleStyles="textTrasformNone"
      />
      {isLoadingInfo ? (
        <LoadingBar />
      ) : (
        <>
          <div className="RegisterService__container">
            {items.map((
              {
                key,
                title,
                description,
                content,
              },
              index,
            ) => (
              <div className="RegisterService__container--item" key={key}>
                <div className="RegisterService__container--item-header">
                  <span className="RegisterService__container--item-header-title">{`${index + 1}. ${title}`}</span>
                  <span className="RegisterService__container--item-header-description">{insertTCIcon(description)}</span>
                </div>
                <div className="RegisterService__container--item-content">
                  {content}
                </div>
              </div>
            ))}
          </div>
          <div className="RegisterService__container--cta">
            <div className="RegisterService__container--cta-text">
              <span className="RegisterService__container--cta-text-label">
                Take Back Service
              </span>
              <span className="RegisterService__container--cta-text-strike">
                {formatCurrencyNumber(20)}
              </span>
              <span className="RegisterService__container--cta-text-value">
                {formatCurrencyNumber(payOnShipFee)}
              </span>
            </div>
            <Button
              type={BUTTON_TYPE.QUATERNARY}
              size={BUTTON_SIZE.LARGE}
              onClick={onSubmit}
              style={getNextButtonStyles(storeConfig)}
              trackerProps={stepTrackerProps}
            >
              I WANT TO RECYCLE!
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

const RegisterServiceContentItem = ({
  title,
  description,
  icon,
  iconBgColor,
  className,
}) => (
  <div className={classNames('RegisterServiceContentItem', className)}>
    <span className="RegisterServiceContentItem__title">{title}</span>
    <span className="RegisterServiceContentItem__description">{description}</span>
    <div
      className="RegisterServiceContentItem__icon-container"
      style={{ backgroundColor: iconBgColor }}
    >
      {icon}
    </div>
  </div>
);

export default Service;
