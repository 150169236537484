import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { Box } from '@mui/material';
import classNames from 'classnames';
import InfiniteScroll from 'react-infinite-scroll-component';

import GiftCardsList from './GiftCardsList';
import {
  BRAND_ID_ROUTE_PARAM,
  CATEGORY_SEARCH_PARAM,
  ROUTE_DASHBOARD,
  ROUTE_GIFT_CARDS,
  ROUTE_GIFT_CARDS_BRAND_ID,
} from '../../config/routes';
import { Button, BUTTON_SIZE, BUTTON_TYPE } from '../../global/Button';
import LoadingBar from '../../global/LoadingBar';
import Tabs from '../../global/Mui/Tabs';
import Tab from '../../global/Mui/Tab';
import GhostIcon from '../../images/ghost-icon.svg';
import IconAll from '../../images/categories-all.svg';
import IconHot from '../../images/categories-hot.svg';
import { getCurrentRouteTitle, getSearchParam } from '../../utils/routes';
import { getGiftCardsCategories, getGiftCardsBrands } from '../../utils/service';
import { getGenericError } from '../../utils/errors';
import { isProd } from '../../../../config/config';
import { LIST_PAGE_SIZE } from '../../config/config';
import SEARCH_TYPE from '../../utils/searchType';
import {
  FLAGS,
  getCategory,
  getCategoryParam,
} from '../Rewards/utils';
import useHasAccessToGiftCards from '../../../../hooks/useHasAccessToGiftCards';
import SearchBar from '../SearchBar';

import './GiftCards.scss';

const LIST_ELEMENT_ID = 'List';

export const FIXED_CATEGORIES = [
  {
    uid: FLAGS.ALL,
    name: 'All Gift Cards',
    iconUrl: IconAll,
    routeName: FLAGS.ALL,
  },
  {
    uid: FLAGS.IS_HOT,
    name: 'Hot!',
    iconUrl: IconHot,
    routeName: FLAGS.IS_HOT,
  },
];

const GiftCards = () => {
  const { search } = useLocation();
  const history = useHistory();
  const timeoutRef = useRef(null);
  const {
    isLoading: isLoadingHasAccessToGiftCards,
    hasAccessToGiftCards,
  } = useHasAccessToGiftCards();

  const [categories, setCategories] = useState([]);
  const [categoryGiftCards, setCategoryGiftCards] = useState([]);
  const [categoryGiftCardsTotal, setCategoryGiftCardsTotal] = useState(0);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingGiftCards, setIsLoadingGiftCards] = useState(true);
  const [page, setPage] = useState(1);
  const [error, setError] = useState('');
  const [giftCardsError, setGiftCardsError] = useState('');
  const [hasLoadedGiftCards, setHasLoadedGiftCards] = useState(false);

  const query = useMemo(() => (
    new URLSearchParams(search)
  ), [search]);

  const giftCardCategoryParam = useMemo(() => (
    getSearchParam(query, CATEGORY_SEARCH_PARAM)
  ), [query]);

  const scrollToTop = useCallback(() => {
    const listElement = document.getElementById(LIST_ELEMENT_ID);

    if (listElement) {
      timeoutRef.current = setTimeout(() => {
        listElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }, 0);
    }
  }, []);

  const loadCategoryGiftCards = useCallback(async (routeName, newCategories) => {
    setIsLoadingGiftCards(true);

    const newPage = routeName === selectedCategory ? page + 1 : 1;

    if (newPage === 1) {
      setCategoryGiftCards([]);
      setCategoryGiftCardsTotal(0);
    }

    const currentTotal = newPage === 1 ? 0 : categoryGiftCardsTotal;
    const currentCategoryGiftCards = newPage === 1 ? [] : [...categoryGiftCards];
    const category = getCategory(newCategories?.length ?
      newCategories : categories, routeName, FIXED_CATEGORIES);

    const {
      data,
      error: categoryGiftCardsError,
    } = await getGiftCardsBrands({
      pageSize: LIST_PAGE_SIZE,
      page: newPage,
      ...getCategoryParam(category.uid),
    });

    if (categoryGiftCardsError) {
      setCategoryGiftCards(currentCategoryGiftCards);
      setCategoryGiftCardsTotal(currentTotal);
      setPage(page);
      setGiftCardsError(categoryGiftCardsError.message || getGenericError());
      setIsLoadingGiftCards(false);
      return;
    }

    setCategoryGiftCards([...currentCategoryGiftCards, ...data.brands]);
    setCategoryGiftCardsTotal(data.total);
    setPage(newPage);
    setGiftCardsError('');
    setIsLoadingGiftCards(false);

    if (!hasLoadedGiftCards) {
      scrollToTop();
    }

    setHasLoadedGiftCards(true);
  }, [
    selectedCategory,
    page,
    categories,
    categoryGiftCardsTotal,
    categoryGiftCards,
    hasLoadedGiftCards,
  ]);

  const loadCategories = useCallback(async () => {
    setIsLoading(true);

    const {
      data,
      error: rewardCategoriesError,
    } = await getGiftCardsCategories({});

    if (rewardCategoriesError) {
      setCategories([]);
      setSelectedCategory('');
      setError(rewardCategoriesError.message || getGenericError());
      setIsLoading(false);
      return;
    }

    const newCategories = [
      ...FIXED_CATEGORIES,
      ...data.categories,
    ];

    const initialSelectedCategory = getCategory(
      newCategories,
      giftCardCategoryParam,
      FIXED_CATEGORIES,
    );

    setCategories(newCategories);
    setSelectedCategory(initialSelectedCategory.routeName);
    setError('');
    setIsLoading(false);
    loadCategoryGiftCards(
      initialSelectedCategory.routeName,
      newCategories,
    );

    query.set(CATEGORY_SEARCH_PARAM, initialSelectedCategory.routeName);

    history.push({
      pathname: ROUTE_GIFT_CARDS,
      search: query.toString(),
    });
  }, [giftCardCategoryParam, loadCategoryGiftCards, history, query]);

  const handleCategoryChange = useCallback((event, newValue) => {
    if (newValue === selectedCategory) {
      return;
    }

    const newCategory = getCategory(categories, newValue);
    setCategoryGiftCards([]);
    setSelectedCategory(newCategory.routeName);
    loadCategoryGiftCards(newCategory.routeName);
    scrollToTop();

    if (event.target) {
      query.set(CATEGORY_SEARCH_PARAM, newValue);
      history.push({
        pathname: ROUTE_GIFT_CARDS,
        search: query.toString(),
      });
    }
  }, [
    history,
    query,
    selectedCategory,
    categories,
    loadCategoryGiftCards,
  ]);

  const handleOnClick = useCallback(({ uid }) => {
    const relativePath = ROUTE_GIFT_CARDS_BRAND_ID.replace(BRAND_ID_ROUTE_PARAM, uid);

    history.push(relativePath);
  }, [history]);

  const handleNoAccess = useCallback(() => {
    setError('You do not have access to gift cards');
    setIsLoading(false);
  }, []);

  useEffect(() => {
    if (hasLoadedGiftCards && giftCardCategoryParam) {
      handleCategoryChange({}, giftCardCategoryParam);
    }
  }, [hasLoadedGiftCards, giftCardCategoryParam]);

  useEffect(() => {
    if (isLoadingHasAccessToGiftCards) {
      return;
    }

    if (hasAccessToGiftCards) {
      loadCategories();
    } else {
      handleNoAccess();
    }
  }, [isLoadingHasAccessToGiftCards, hasAccessToGiftCards]);

  useEffect(() => (
    () => clearTimeout(timeoutRef.current)
  ), []);

  return (
    <div className="GiftCardsView">
      <div className="GiftCardsView__content">
        {error || ((isLoading || isLoadingHasAccessToGiftCards) ? (
          <LoadingBar className="GiftCardsView__content--loader" />
        ) : (
          <>
            <Box className={classNames('GiftCardsView__content--controls', { hasEnvBanner: !isProd() })}>
              <div className="GiftCardsView__content--header">
                <div>
                  <h2 className="GiftCardsView__content--header-title">
                    {getCurrentRouteTitle()}
                  </h2>
                  <p className="GiftCardsView__content--header-subtitle">
                    Get gift cards by earning TrashieCash™ when recycling with the Take Back Bag.
                  </p>
                </div>
                <Box className="GiftCardsView__content--controls-options">
                  <SearchBar
                    searchType={SEARCH_TYPE.GIFT_CARDS}
                  />
                </Box>
              </div>
              <Tabs
                value={selectedCategory}
                onChange={handleCategoryChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="gift cards categories"
                withFilters={false}
                className="GiftCardsView__content--controls-tabs"
                backgroundColor="var(--trashie-primary-thrifty-blue)"
              >
                {categories.map(({ uid, name, iconUrl, routeName }) => (
                  <Tab key={uid} value={routeName} label={name} icon={<img src={iconUrl} alt="tab icon" />} />
                ))}
              </Tabs>
            </Box>
            <Box
              id={LIST_ELEMENT_ID}
              className={classNames('GiftCardsView__content--pane', { hasEnvBanner: !isProd() })}
            >
              {!isLoadingGiftCards && categoryGiftCards.length === 0 ? (
                <div className="GiftCardsView__content--empty">
                  <img src={GhostIcon} alt="ghost icon" />
                  <div className="GiftCardsView__content--empty-title">
                    No gift cards found
                  </div>
                  <div className="GiftCardsView__content--empty-subtitle">
                    Try a different category or go back to our rewards section.
                  </div>
                  <Button
                    type={BUTTON_TYPE.QUATERNARY}
                    size={BUTTON_SIZE.LARGE}
                    onClick={() => history.push(ROUTE_DASHBOARD)}
                  >
                    Back to rewards
                  </Button>
                </div>
              ) : (
                <InfiniteScroll
                  dataLength={categoryGiftCards.length}
                  next={() => loadCategoryGiftCards(selectedCategory)}
                  hasMore={categoryGiftCards.length < categoryGiftCardsTotal}
                  className="GiftCardsView__content--scroll"
                >
                  <GiftCardsList
                    giftCardsList={categoryGiftCards}
                    onClick={handleOnClick}
                  />
                </InfiniteScroll>
              )}
              {(isLoadingGiftCards) && (
                <LoadingBar className="GiftCardsView__content--loader" />
              )}
              {giftCardsError && giftCardsError}
            </Box>
          </>
        ))}
      </div>
    </div>
  );
};

export default GiftCards;
