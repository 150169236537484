import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { getRewardsOffer } from '../../../../../utils/service';
import { REWARD_STATUSES } from '../../../../../config/rewards';
import { ROUTE_REWARDS, ROUTE_DEALS } from '../../../../../config/routes';
import CardSection from '../../../Components/CardSection';
import { Button, BUTTON_SIZE } from '../../../../../global/Button';
import DealCard from '../../../../../global/DynamicDealsBanner/DealCard';
import LoadingBar from '../../../../../global/LoadingBar';
import Slider, { CAROUSEL_TYPES } from '../../../../../global/Slider';
import { getOptionButtonStyles } from '../../../../../utils/tbbRegistration';
import {
  getTrackerId,
  POSTHOG_CAPTURE_ATTRIBUTES,
  TRACKER_IDS,
} from '../../../../../config/tracker';
import './ExploreMoreRewards.scss';

const {
  tbb: {
    lastStep: { rewardsCta: rewardsCtaItem },
  },
} = TRACKER_IDS;

const getRewards = (isHot, isRewards) => getRewardsOffer({
  page: 1,
  pageSize: 5,
  isHot,
  sort: 'sortRank',
  status: REWARD_STATUSES.ACTIVE,
  ...(isRewards ? { exchangeValueMin: 1 } : { exchangeValueMax: 0 }),
});

export default function ExploreMoreRewards({
  title,
  buttonText,
  storeConfig,
  className,
  buttonType,
  isRewards = true,
}) {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [rewards, setRewards] = useState([]);

  useEffect(async () => {
    setIsLoading(true);
    let tempRewards = [];

    let result = await getRewards(true, isRewards);
    if (!result.error) {
      tempRewards = result.data.rewards;
    }

    if (tempRewards.length < 5) {
      result = await getRewards(false, isRewards);
      if (!result.error) {
        tempRewards = result.data.rewards;
      }
    }

    setRewards(tempRewards);
    setIsLoading(false);
  }, [isRewards]);

  const onCtaClick = useCallback(() => {
    history.push(isRewards ? ROUTE_REWARDS : ROUTE_DEALS);
  }, [isRewards]);

  return (
    <CardSection title={title} className={classNames('ExploreMoreRewards', className)}>
      <div className="ExploreMoreRewards__container">
        {isLoading ? (
          <LoadingBar />
        ) : (
          <button
            type="button"
            aria-label="reward item"
            className="ExploreMoreRewards__container--reward-wrapper"
            onClick={onCtaClick}
            {...{
              [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(rewardsCtaItem),
            }}
          >
            <Slider
              carouselType={CAROUSEL_TYPES.FADE}
              pauseOnHover={false}
              interval={1000}
              trackPadding={false}
              slides={rewards.map((reward) => ({
                key: reward.uid,
                slide: (
                  <DealCard
                    dealItem={reward}
                    isCircleVariant
                    containerClassName="ExploreMoreRewards__container--reward-card"
                  />
                ),
              }))}
            />
          </button>
        )}
        <Button
          type={buttonType}
          size={BUTTON_SIZE.LARGE}
          onClick={onCtaClick}
          style={getOptionButtonStyles(storeConfig, true)}
          trackerProps={{
            [POSTHOG_CAPTURE_ATTRIBUTES.TRACKER_ID]: getTrackerId(rewardsCtaItem),
          }}
        >
          {buttonText}
        </Button>
      </div>
    </CardSection>
  );
}
