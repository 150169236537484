import { useState } from 'react';

import MyRewardItem from './MyRewardItem';
import MyGiftCardItem from './MyGiftCardItem';

import './MyRewardsList.scss';

const MyRewardsList = ({ rewardsList, status, onClickIsUsed, showIsUsed }) => {
  const [itemCopied, setItemCopied] = useState(null);

  return (
    <div
      role="list"
      className="MyRewardsList"
    >
      <div className="MyRewardsList__rewardRow">
        {rewardsList.map((perk) => (perk.reward ?
          (
            <MyRewardItem
              key={`MyRewardItem-${perk.uid}`}
              status={status}
              rewardItem={perk}
              onCopy={(uid) => setItemCopied(uid)}
              isCopied={itemCopied === perk.uid}
              onClickIsUsed={onClickIsUsed}
              showIsUsed={showIsUsed}
            />
          ) : (
            <MyGiftCardItem
              key={`MyRewardItem-${perk.uid}`}
              giftCardItem={perk}
              onCopy={(uid) => setItemCopied(uid)}
              isCopied={itemCopied === perk.uid}
              showIsUsed={showIsUsed}
              onClickIsUsed={onClickIsUsed}
            />
          )))}
      </div>
    </div>
  );
};

export default MyRewardsList;
