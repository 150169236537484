import { useEffect, useState, useCallback, useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import _ from 'lodash';

import { REGISTER_CODE_PARAM, ROUTE_TAKE_BACK_BAGS, ROUTE_REGISTER } from '../../config/routes';
import ShippingInfo from '../Register/Steps/ShippingInfo/ShippingInfo';
import ListHeaders from '../../global/ListHeaders';
import LoadingBar from '../../global/LoadingBar';
import { Invalid } from '../Register/Steps';
import { HELP_URL } from '../../config/externalURL';
import { getTakeBackBagDetails, postShippingLabelRegenerate } from '../../utils/service';
import { isExpiredLabel, shippingProviderStatus } from '../../utils/easyPost';
import { errorCodes } from '../../utils/errors';
import { REDEMPTION_CODE_STATUS_IDS, RECYCLING_PRODUCT_TYPES } from '../../utils/tbbRegistration';
import ShippingMethodsModal from '../../global/ShippingMethodsModal';

import './TakeBackBagDetails.scss';

const backButtonText = 'GO TO MY SHIPPING LABELS';

const TakeBackBagDetails = () => {
  const [code, setCode] = useState('');
  const [shippingMethod, setShippingMethod] = useState('');
  const [recyclingProductTypeId, setRecyclingProductTypeId]
    = useState(RECYCLING_PRODUCT_TYPES.TEXTIL);
  const [expirationLabelDate, setExpirationLabelDate] = useState('');
  const [printLabelUrl, setPrintLabelUrl] = useState('');
  const [qrCodeImgUrl, setQrCodeImgUrl] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [address, setAddress] = useState({});
  const [showShippingMethods, setShowShippingMethods] = useState(false);
  const [shippingMethodSelected, setShippingMethodSelected] = useState({});
  const [selectedShippingMethodIndex, setSelectedShippingMethodIndex] = useState(0);
  const [shippingState, setShippingState] = useState(shippingProviderStatus.unknown);
  const [storeUid, setStoreUid] = useState('');
  const [isExpiredCode, setIsExpiredCode] = useState(false);

  const { search } = useLocation();
  const history = useHistory();
  const query = new URLSearchParams(search);

  const isShipped = useMemo(() => (
    shippingState === shippingProviderStatus.in_transit ||
    shippingState === shippingProviderStatus.out_for_delivery
  ), [shippingState]);

  const dateShipmentSelector = useCallback((shipment) => {
    if (shipment.shipmentStatus === shippingProviderStatus.delivered) {
      setExpirationLabelDate(shipment.deliveredAt);
    } else if (shipment.shipmentStatus === shippingProviderStatus.in_transit ||
      shipment.shipmentStatus === shippingProviderStatus.out_for_delivery) {
      setExpirationLabelDate(shipment.shippedAt);
    } else {
      setExpirationLabelDate(shipment.shippingLabelExpiresAt);
    }
  }, [code]);

  const titleSelector = useMemo(() => {
    if (shippingState === shippingProviderStatus.delivered) {
      return 'Delivered bag';
    }
    if (isShipped) {
      return 'Shipped bag';
    }
    return shippingMethod?.configuration?.shippingTitle;
  }, [shippingState, shippingMethod]);

  const regenerateLabel = useCallback(
    async (registerCode) => {
      setShowShippingMethods(false);
      const {
        data,
        error: regenerateError,
      } = await postShippingLabelRegenerate({
        redemptionCode: registerCode,
        shippingMethodId: shippingMethodSelected?.uid,
      });

      if (regenerateError || _.isEmpty(data)) {
        setError('Oops! Something went wrong.');
        setIsLoading(false);
        return;
      }
      const labelData = JSON.parse(data?.labeldata);
      const qrUrl = labelData?.forms?.find(({ form_type: formType }) => formType === 'label_qr_code')?.form_url || data?.shipment?.qrLabelUrl;
      const printLabelUrlTmp = (labelData?.postage_label?.label_pdf_url ?
        labelData?.postage_label?.label_pdf_url :
        labelData?.postage_label?.label_url) || qrUrl;
      dateShipmentSelector(data?.shipment);
      setShippingState(data?.shipmentStatus);
      setQrCodeImgUrl(qrUrl);
      setPrintLabelUrl(printLabelUrlTmp);
      setShippingMethod(data?.shipment?.shippingMethod);
      setRecyclingProductTypeId(data?.recyclingProduct?.recyclingProductTypeId);
      setAddress(data?.shipment?.fromAddress ?? { zip: data?.shipment?.zip });
      setError('');
      setIsLoading(false);
    },
    [shippingMethodSelected],
  );

  const getBagData = useCallback(async () => {
    setIsLoading(true);

    const registerCode = query.get(REGISTER_CODE_PARAM);
    setCode(registerCode);

    const {
      data: detailsData,
      error: detailsError,
    } = await getTakeBackBagDetails(registerCode);

    if (detailsError) {
      setIsLoading(false);
      setError(errorCodes[detailsError.code] ?? 'Oops! Something went wrong.');
      return;
    }

    if (
      detailsData.statusId === REDEMPTION_CODE_STATUS_IDS.PENDING ||
      detailsData.statusId === REDEMPTION_CODE_STATUS_IDS.BLOCKED
    ) {
      setIsLoading(false);
      setError('Oops! Something went wrong.');
      return;
    }

    const data = detailsData;
    setStoreUid(data.storeUid);
    setAddress(data.shipment?.fromAddress ?? { zip: data.shipment?.zip });

    if (
      isExpiredLabel(data.shipment.shippingLabelExpiresAt) &&
      (data.shipment.shipmentStatus === shippingProviderStatus.unknown ||
        data.shipment.shipmentStatus === shippingProviderStatus.pre_transit)
    ) {
      setIsExpiredCode(true);
      if (data.shipment.shippingMethod?.configuration?.nonRegenerableLabel) {
        setShowShippingMethods(true);
        return;
      }
      await regenerateLabel(registerCode);
      return;
    }

    const labelData = JSON.parse(data.labeldata);
    const qrUrl = labelData?.forms?.find(({ form_type: formType }) => formType === 'label_qr_code')?.form_url || data?.shipment?.qrLabelUrl;
    const printLabelUrlTmp = (labelData?.postage_label?.label_pdf_url ?
      labelData.postage_label.label_pdf_url :
      labelData?.postage_label?.label_url) || qrUrl;
    dateShipmentSelector(data.shipment);
    setShippingState(data.shipmentStatus);
    setQrCodeImgUrl(qrUrl);
    setPrintLabelUrl(printLabelUrlTmp);
    setShippingMethod(data.shipment?.shippingMethod);
    setRecyclingProductTypeId(data?.recyclingProduct?.recyclingProductTypeId);
    setError('');
    setIsLoading(false);
  }, [query]);

  const handleHelpClick = useCallback(() => {
    window.location.href = HELP_URL;
  }, []);

  const handleInvalidCodeClick = useCallback(() => {
    history.push(ROUTE_REGISTER);
  }, []);

  const handleOnSubmit = useCallback(async () => {
    await regenerateLabel(code);
  }, [code, shippingMethodSelected]);

  useEffect(() => {
    getBagData();
  }, []);

  return (
    <div className="TakeBackBagDetails">
      {isLoading ? <LoadingBar /> : (
        <div className="TakeBackBagDetails__container">
          <ListHeaders
            title={titleSelector}
            backButtonText={backButtonText}
            returnActionRoute={ROUTE_TAKE_BACK_BAGS}
          />
          <div className="TakeBackBagDetails__container--content">
            {error ? (
              <Invalid
                code={code}
                message={error}
                onClick={handleInvalidCodeClick}
                onCancel={handleHelpClick}
              />
            ) : (
              <ShippingInfo
                code={code}
                shippingMethod={shippingMethod}
                expirationLabelDate={expirationLabelDate}
                printLabelUrl={printLabelUrl}
                qrCodeImgUrl={qrCodeImgUrl}
                shippingState={shippingState}
                address={address}
                recyclingProductType={recyclingProductTypeId}
              />
            )}
          </div>
        </div>
      )}

      <ShippingMethodsModal
        showModal={showShippingMethods}
        onClose={() => setShowShippingMethods(false)}
        storeId={storeUid}
        descriptionText="A USPS label can’t be generated at this time. Please select a drop off option:"
        recyclingProductTypeId={recyclingProductTypeId}
        setShippingMethodSelected={setShippingMethodSelected}
        selectedShippingMethodIndex={selectedShippingMethodIndex}
        setSelectedShippingMethodIndex={setSelectedShippingMethodIndex}
        isExpiredLabel={isExpiredCode}
        onSubmit={handleOnSubmit}
        address={address}
        onCancel={() => setShowShippingMethods(false)}
        canClose={false}
      />
    </div>
  );
};

export default TakeBackBagDetails;
