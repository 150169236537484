/* eslint-disable react/jsx-indent */
import React from 'react';

const CircleChevronDown = ({ color = '#292829', size = 20 }) => (
    <svg width={size} height={size} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="Icon / chevron-down-circle">
            <g id="icon">
                <path d="M10.0001 1.46448C5.40508 1.46448 1.66675 5.20281 1.66675 9.79781C1.66675 14.3928 5.40508 18.1311 10.0001 18.1311C14.5951 18.1311 18.3334 14.3928 18.3334 9.79781C18.3334 5.20281 14.5951 1.46448 10.0001 1.46448ZM10.0001 16.4645C6.32425 16.4645 3.33341 13.4736 3.33341 9.79781C3.33341 6.12198 6.32425 3.13114 10.0001 3.13114C13.6759 3.13114 16.6667 6.12198 16.6667 9.79781C16.6667 13.4736 13.6759 16.4645 10.0001 16.4645Z" fill={color} />
                <path d="M10.0001 11.1195L6.42261 7.542L5.24427 8.72034L10.0001 13.4762L14.7559 8.72034L13.5776 7.542L10.0001 11.1195Z" fill={color} />
            </g>
        </g>
    </svg>
);

export default CircleChevronDown;
